import { TASKS_FETCH, TASKS_FETCH_SUCCESS, TASKS_FETCH_FAILURE } from 'activity-feed-ui/redux/actionTypes/ActionTypes';
const initialState = {
  callTaskId: null,
  callTasksTotal: null,
  emailTaskId: null,
  emailTasksTotal: null,
  todoTaskId: null,
  todoTasksTotal: null,
  loading: true,
  hasError: false
};
export default ((state = initialState, action) => {
  switch (action.type) {
    case TASKS_FETCH:
      return initialState;
    case TASKS_FETCH_SUCCESS:
      {
        const {
          callTasksData,
          emailTasksData,
          todoTasksData
        } = action.payload;
        return Object.assign({}, state, {
          callTaskId: callTasksData.taskId,
          callTasksTotal: callTasksData.total,
          emailTaskId: emailTasksData.taskId,
          emailTasksTotal: emailTasksData.total,
          todoTaskId: todoTasksData.taskId,
          todoTasksTotal: todoTasksData.total,
          loading: false,
          hasError: false
        });
      }
    case TASKS_FETCH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        hasError: true
      });
    default:
      return state;
  }
});