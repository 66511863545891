import I18n from 'I18n';
import { fromJS } from 'immutable';
import identity from 'transmute/identity';
// @ts-expect-error Untyped
import createAction from 'flux-actions/createAction';
import { CONTACT_CREATE, CONTACT_CREATE_SUCCESS, CONTACT_CREATE_FAILURE } from 'activity-feed-ui/redux/actionTypes/ActionTypes';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import { closeModalAction, openModalAction } from './OverlayActions';
import { CREATE_CONTACT_MODAL } from '../../Constants';
const createContactStart = createAction(CONTACT_CREATE, identity);
const createContactSuccess = createAction(CONTACT_CREATE_SUCCESS, identity);
const createContactFailure = createAction(CONTACT_CREATE_FAILURE, identity);
const noop = () => {};
export const createContact = (origin, onSuccess = noop) => (dispatch, getState) => {
  dispatch(createContactStart());
  dispatch(openModalAction(CREATE_CONTACT_MODAL, {
    mainRecipient: origin,
    onConfirm: ({
      createdObject
    }) => {
      const contact = fromJS(Object.assign({}, createdObject, {
        vid: createdObject.objectId,
        'is-contact': true
      }));
      const oldBundle = getState().activities.activityBundles.get(origin.getUniqueKey());
      const newBundle = oldBundle.updateOriginWithContact(contact);
      dispatch(createContactSuccess({
        oldBundle,
        newBundle
      }));
      onSuccess();
      FloatingAlertStore.addAlert({
        titleText: I18n.text('alerts.contactCreatedSuccessTitle'),
        message: I18n.text('alerts.contactCreatedSuccessMessage', {
          email: origin.get('email')
        }),
        type: 'success'
      });
      dispatch(closeModalAction());
    },
    onReject: ({
      error
    }) => {
      if (error) {
        FloatingAlertStore.addAlert({
          message: I18n.text('errors.createContact'),
          titleText: I18n.text('errors.error'),
          type: 'danger'
        });
        dispatch(createContactFailure(error));
      }
      dispatch(closeModalAction());
    }
  }));
};