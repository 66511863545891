import SearchQueryDataRecord from 'activity-feed-ui/records/SearchQueryDataRecord';
import { ACTIVITIES_FOR_ORIGIN, ACTIVITIES_FOR_ORIGIN_FAILURE, ACTIVITIES_FOR_ORIGIN_SUCCESS, ACTIVITY_FEED_SEARCH, ACTIVITY_FEED_SEARCH_FAILURE, ACTIVITY_FEED_SEARCH_SUCCESS, APP_INIT, CONTACT_CREATE_SUCCESS, MONTHLY_COUNT_FETCH_SUCCESS, SET_CREATED_AFTER_FILTER, SET_SEARCH_QUERY_DATA, UPDATE_ACTIVITY_BUNDLE } from 'activity-feed-ui/redux/actionTypes/ActionTypes';
import { OrderedMap } from 'immutable';
import omit from 'transmute/omit';
import { ACTIVITY_BUNDLE_DELETED_SUCCESS } from '../actionTypes/ActionTypes';
const initialState = {
  searchQueryData: new SearchQueryDataRecord(),
  activityBundles: OrderedMap(),
  monthlyCount: 0,
  createdAfterFilter: ''
};
const Activities = (state = initialState, action) => {
  switch (action.type) {
    case APP_INIT:
      {
        const {
          earlyRequestData
        } = action.payload;
        return Object.assign({}, state, {
          searchQueryData: earlyRequestData ? SearchQueryDataRecord.createFromEarlyRequesterData(earlyRequestData.toObject()) : SearchQueryDataRecord.createFromQueryParams(),
          activityBundles: earlyRequestData ? state.activityBundles.merge(earlyRequestData.get('results')) : state.activityBundles
        });
      }
    case SET_CREATED_AFTER_FILTER:
      return Object.assign({}, state, {
        createdAfterFilter: action.payload
      });
    case SET_SEARCH_QUERY_DATA:
      return Object.assign({}, state, {
        activityBundles: OrderedMap(),
        searchQueryData: action.payload
      });
    case ACTIVITY_BUNDLE_DELETED_SUCCESS:
      return Object.assign({}, state, {
        activityBundles: omit([action.payload.originUniqueKey], state.activityBundles)
      });
    case ACTIVITY_FEED_SEARCH:
      return Object.assign({}, state, {
        searchQueryData: state.searchQueryData.merge({
          isSearching: true,
          offset: action.payload.clearActivities ? 0 : state.searchQueryData.offset
        }),
        activityBundles: action.payload.clearActivities ? OrderedMap() : state.activityBundles
      });
    case ACTIVITY_FEED_SEARCH_SUCCESS:
      {
        const {
          hasMore,
          offset,
          results
        } = action.payload;
        return Object.assign({}, state, {
          searchQueryData: state.searchQueryData.merge({
            isSearching: false,
            searchError: false,
            hasMore,
            offset
          }),
          activityBundles: state.activityBundles.merge(results)
        });
      }
    case ACTIVITY_FEED_SEARCH_FAILURE:
      return Object.assign({}, state, {
        searchQueryData: state.searchQueryData.merge({
          isSearching: false,
          searchError: true
        })
      });
    case ACTIVITIES_FOR_ORIGIN:
      return Object.assign({}, state, {
        activityBundles: state.activityBundles.setIn([action.payload.activityBundle.getUniqueKey(), 'isFetching'], true)
      });
    case ACTIVITIES_FOR_ORIGIN_SUCCESS:
      return Object.assign({}, state, {
        activityBundles: state.activityBundles.update(action.payload.activityBundle.getUniqueKey(), bundle => bundle.addActivities({
          activities: action.payload.results,
          hasMore: action.payload.hasMore,
          offset: action.payload.offset
        }))
      });
    case ACTIVITIES_FOR_ORIGIN_FAILURE:
      return Object.assign({}, state, {
        activityBundles: state.activityBundles.setIn([action.payload.activityBundle.getUniqueKey(), 'isFetching'], false)
      });
    case MONTHLY_COUNT_FETCH_SUCCESS:
      return Object.assign({}, state, {
        monthlyCount: action.payload
      });
    case CONTACT_CREATE_SUCCESS:
      return Object.assign({}, state, {
        activityBundles: state.activityBundles.mapKeys(key => key === action.payload.oldBundle.getUniqueKey() ? action.payload.newBundle.getUniqueKey() : key)
        // @ts-expect-error ts-migrate(2551) FIXME: Property 'setIn' does not exist on type 'Keyed<any... Remove this comment to see the full error message
        .setIn([action.payload.newBundle.getUniqueKey()], action.payload.newBundle)
      });
    case UPDATE_ACTIVITY_BUNDLE:
      return Object.assign({}, state, {
        activityBundles: state.activityBundles.setIn([action.payload.newBundle.getUniqueKey()], action.payload.newBundle)
      });
    default:
      return state;
  }
};
export default Activities;