'use es6';

import PortalSettingsRecord from 'sales-clients-common/data/PortalSettingsRecord';
import { PORTAL_SETTINGS_FETCH, PORTAL_SETTINGS_FETCH_FAILED, PORTAL_SETTINGS_FETCH_SUCCEEDED } from 'sales-clients-common/actions/ActionTypes';
export default function portalSettings(state = PortalSettingsRecord(), {
  type,
  payload
}) {
  switch (type) {
    case PORTAL_SETTINGS_FETCH:
      return PortalSettingsRecord();
    case PORTAL_SETTINGS_FETCH_SUCCEEDED:
      return state.withMutations(mutableSettings => {
        mutableSettings.mergeIn(['settings'], payload).set('isLoading', false);
      });
    case PORTAL_SETTINGS_FETCH_FAILED:
      return state.set('lastError', payload);
    default:
      return state;
  }
}