import ActivityTypes from 'activity-feed-ui/constants/ActivityTypes';
import { getFormLink } from 'activity-feed-ui/lib/Links';
function getEmailProps(activity) {
  const {
    emailSubject,
    emailLink
  } = activity.activityMeta.toObject();
  return {
    emailLink,
    emailSubject: emailSubject || ''
  };
}
export default function (activity) {
  switch (activity.activityType) {
    case ActivityTypes.EMAIL_CLICK:
      {
        const {
          link,
          linkText
        } = activity.activityMeta.toObject();
        return Object.assign({
          link,
          linkText: linkText || link
        }, getEmailProps(activity));
      }
    case ActivityTypes.EMAIL_OPEN:
    case ActivityTypes.EMAIL_TRACKER_CREATE:
      return getEmailProps(activity);
    case ActivityTypes.HUBSPOT_REVISIT:
    case ActivityTypes.COMPANYPROSPECTS_REVISIT:
      {
        const {
          page,
          pageLink
        } = activity.activityMeta.toObject();
        return {
          page,
          pageLink
        };
      }
    case ActivityTypes.PRESENTATION_REVISIT:
      {
        const {
          documentName,
          documentLink
        } = activity.activityMeta.toObject();
        return {
          documentLink,
          documentName
        };
      }
    case ActivityTypes.MEETING_BOOKED:
      {
        const subject = activity.getIn(['activityMeta', 'meeting', 'eventBooking', 'subject']);
        const link = activity.getIn(['activityMeta', 'meeting', 'eventBooking', 'link']);
        return {
          subject,
          link
        };
      }
    case ActivityTypes.FORM_SUBMITTED:
      {
        const {
          formGuid,
          formName
        } = activity.activityMeta.toObject();
        return {
          link: getFormLink(formGuid),
          formName
        };
      }
    default:
      return {};
  }
}