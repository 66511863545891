import I18n from 'I18n';
import valueSeq from 'transmute/valueSeq';
import { deleteRequest, get, post } from './HubSpotClient';
import { List, fromJS } from 'immutable';
import ActivityRecord from 'activity-feed-ui/records/ActivityRecord';
import { BundledActivityLimit } from 'activity-feed-ui/Constants';
import formatActivityResults from 'activity-feed-ui/lib/formatActivityResults';
import ActivityTypes from 'activity-feed-ui/constants/ActivityTypes';
import { createdAfterFilterValueToTime } from 'activity-feed-ui/utils/createdAfterFilterUtils';
const SUPPORTED_ACTIVITIES = valueSeq(ActivityTypes);
const baseUrl = 'activity-feed/v2';
let createdBeforeCacheKey = null;
function resetCacheKey() {
  createdBeforeCacheKey = I18n.moment().utc().valueOf();
}
export default {
  searchBundled(query, createdAfter) {
    if (query.offset === 0 || createdBeforeCacheKey === null) {
      resetCacheKey();
    }
    return post(`${baseUrl}/search/bundled`, {
      data: Object.assign({
        supportedActivityTypes: SUPPORTED_ACTIVITIES,
        createdBefore: createdBeforeCacheKey,
        createdAfter: createdAfterFilterValueToTime[createdAfter]()
      }, query)
    }).then(formatActivityResults);
  },
  search({
    searchQuery = '',
    filters = [],
    offset = 0
  }) {
    if (offset === 0 || createdBeforeCacheKey === null) {
      resetCacheKey();
    }
    return post(`${baseUrl}/search`, {
      data: {
        supportedActivityTypes: SUPPORTED_ACTIVITIES,
        createdBefore: createdBeforeCacheKey,
        filters,
        offset,
        query: searchQuery,
        limit: BundledActivityLimit
      }
    }).then(response => {
      return fromJS(response).update('results', List(), results => results.map(activity => ActivityRecord.create(activity)));
    });
  },
  fetchMonthlyCount() {
    return get(`${baseUrl}/search/monthly/count`);
  },
  // If activity type is email-related, mutes all activities with same thread ID
  muteThreadNotifications({
    activityType,
    activityOriginType,
    activityOriginId,
    threadId
  }) {
    return post(`${baseUrl}/activities/mute/${activityType}/${activityOriginType}/${activityOriginId}/${threadId}`);
  },
  // If activity type is email-related, un-mutes all activities with same thread ID
  unmuteThreadNotifications({
    activityType,
    activityOriginType,
    activityOriginId,
    threadId
  }) {
    return post(`${baseUrl}/activities/unmute/${activityType}/${activityOriginType}/${activityOriginId}/${threadId}`);
  },
  deleteActivityBundle({
    activityOriginType,
    activityOriginId
  }) {
    return deleteRequest(`${baseUrl}/activities/bundled/${activityOriginType}/${activityOriginId}`);
  },
  addOnSearch({
    domains = [],
    contactIds = [],
    companyIds = []
  }) {
    return post(`${baseUrl}/search/activity-add-ons`, {
      data: {
        domains,
        contactIds,
        companyIds
      }
    });
  }
};