import { useRef, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import debounce from 'transmute/debounce';
import { REPORTING_DASHBOARD } from 'activity-feed-ui/constants/StreamSources';
import { searchActivities as searchActivitiesAction } from 'activity-feed-ui/redux/actions/ActivityStreamActions';
import * as FreeUserLimits from 'activity-feed-ui/lib/FreeUserLimits';
import { shouldShowOnboarding } from 'activity-feed-ui/redux/selectors/onboardingSelectors';
import StreamSource from 'activity-feed-ui/context/StreamSource';
import { getHeaderOffset, getScrollableContainerState, handleScrollAndUpdateRefs } from 'activity-feed-ui/utils/scrollUtils';
import { hideOnboarding } from 'activity-feed-ui/redux/actions/UserAttributeActions';
function useScrollListenersEffect({
  scrollableContainerClassName,
  headerOffset,
  handleScroll,
  activityBundles,
  searchQueryData
}) {
  useEffect(() => {
    const {
      scrollableNode
    } = getScrollableContainerState({
      scrollableContainerClassName,
      headerOffset
    });
    if (scrollableNode) {
      scrollableNode.addEventListener('scroll', handleScroll);
      scrollableNode.addEventListener('resize', handleScroll);
    }
    return function cleanup() {
      scrollableNode.removeEventListener('scroll', handleScroll);
      scrollableNode.removeEventListener('resize', handleScroll);
    };
  },
  // we don't want handleScroll to have an effect on this running
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [scrollableContainerClassName, headerOffset, activityBundles, searchQueryData.searchError]);
}
function useCancelFetchMoreOnSearchChangedEffect({
  searchQueryData,
  debounceFetchMoreRef
}) {
  useEffect(() => {
    debounceFetchMoreRef.current.cancel();
  }, [searchQueryData.searchQuery, searchQueryData.filters, searchQueryData.searchError, debounceFetchMoreRef]);
}
function useHandleScrollOnSearchFinishedEffect({
  searchQueryData,
  handleScroll,
  activityBundles
}) {
  useEffect(() => {
    if (!searchQueryData.isSearching && !searchQueryData.searchError) {
      handleScroll();
    }
  },
  // we don't want handleScroll to have an effect on this running
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [searchQueryData.isSearching, searchQueryData.searchError, activityBundles]);
}
const LoadActivityScrollContainer = ({
  children,
  activityBundles,
  isOverLimit,
  scrollableContainerClassName,
  searchActivities,
  searchQueryData,
  showOnboarding,
  useCondensedView,
  hideOnboardingAction,
  dismissOnboarding
}) => {
  // hide onboarding in future if any activities load
  if (!activityBundles.isEmpty() && !dismissOnboarding) {
    hideOnboardingAction();
  }
  const streamSource = useContext(StreamSource);
  const debounceFetchMoreRef = useRef(debounce(200, searchActivities));
  const isBackgroundRefreshButtonFixedRef = useRef(streamSource === REPORTING_DASHBOARD);
  const headerOffsetRef = useRef(getHeaderOffset({
    useCondensedView,
    streamSource
  }));
  const handleScroll = () => {
    handleScrollAndUpdateRefs({
      scrollableContainerClassName,
      showOnboarding,
      isOverLimit,
      searchQueryData,
      activityBundles,
      headerOffsetRef,
      isBackgroundRefreshButtonFixedRef,
      debounceFetchMoreRef
    });
  };
  useScrollListenersEffect({
    scrollableContainerClassName,
    headerOffset: headerOffsetRef.current,
    handleScroll,
    activityBundles,
    searchQueryData
  });
  useCancelFetchMoreOnSearchChangedEffect({
    searchQueryData,
    debounceFetchMoreRef
  });
  useHandleScrollOnSearchFinishedEffect({
    searchQueryData,
    handleScroll,
    activityBundles
  });
  return children;
};
export default connect(state => ({
  searchQueryData: state.activities.searchQueryData,
  showOnboarding: shouldShowOnboarding(state),
  useCondensedView: state.ui.useCondensedView,
  activityBundles: state.activities.activityBundles,
  isOverLimit: FreeUserLimits.isOverLimit(state.activities.monthlyCount),
  dismissOnboarding: state.userAttributes.dismissOnboarding
}), {
  searchActivities: searchActivitiesAction,
  hideOnboardingAction: hideOnboarding
})(LoadActivityScrollContainer);