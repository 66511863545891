import text from 'I18n/utils/unescapedText';
// https://git.hubteam.com/HubSpot/customer-data-objects/blob/master/static/js/constants/ObjectTypes.js

export const COMPANY = 'COMPANY';
export const CONTACT = 'CONTACT';
export const DEAL = 'DEAL';
export const TICKET = 'TICKET';

// TODO: To support custom objects we need to stop hardcoding this.
export const CONTACT_ID = '0-1';
export const NEW_CONTACT_ID = 'new-0-1';
export const COMPANY_ID = '0-2';
export const DEAL_ID = '0-3';
export const TICKET_ID = '0-5';
export const CONTACT_ACTIONS_CREATE_AND_ASSOCIATE = 'CREATE_AND_ASSOCIATE';
export const CONTACT_ACTIONS_NONE = 'NONE';
export const crmDealStageToText = dealStage => {
  // Avoid missing translation keys
  switch (dealStage) {
    case 'appointmentscheduled':
    case 'qualifiedtobuy':
    case 'presentationscheduled':
    case 'decisionmakerboughtin':
    case 'contractsent':
    case 'closedwon':
    case 'closedlost':
      return text(`crm.dealStages.${dealStage}`);
    default:
      return null;
  }
};
export const ASSOCIATIONS_DEFAULT_SETTINGS = {
  [CONTACT_ID]: true,
  [NEW_CONTACT_ID]: true,
  [COMPANY_ID]: true,
  [DEAL_ID]: true,
  [TICKET_ID]: false
};
export const ASSOCIATIONS_DEFAULT_FALLBACK_SETTINGS = {
  [CONTACT_ID]: true,
  [NEW_CONTACT_ID]: false,
  [COMPANY_ID]: false,
  [DEAL_ID]: false,
  [TICKET_ID]: false
};

// Copied from https://git.hubteam.com/HubSpot/customer-data-objects/blob/master/static/js/associations/AssociationTypes.js

const ENGAGEMENT_TO_COMPANY = 'ENGAGEMENT_TO_COMPANY';
const ENGAGEMENT_TO_CONTACT = 'ENGAGEMENT_TO_CONTACT';
const ENGAGEMENT_TO_DEAL = 'ENGAGEMENT_TO_DEAL';
const ENGAGEMENT_TO_TICKET = 'ENGAGEMENT_TO_TICKET';
const ENGAGEMENT_ASSOCIATION_ID_MAP = {
  [COMPANY_ID]: 8,
  // ENGAGEMENT_TO_COMPANY
  [CONTACT_ID]: 10,
  // ENGAGEMENT_TO_CONTACT
  [DEAL_ID]: 12,
  // ENGAGEMENT_TO_DEAL
  [TICKET_ID]: 18 // ENGAGEMENT_TO_TICKET
};
const ENGAGEMENT_ASSOCIATION_TYPE_MAP = {
  [COMPANY_ID]: ENGAGEMENT_TO_COMPANY,
  [CONTACT_ID]: ENGAGEMENT_TO_CONTACT,
  [DEAL_ID]: ENGAGEMENT_TO_DEAL,
  [TICKET_ID]: ENGAGEMENT_TO_TICKET
};
export const buildAddAssociationRequest = (engagementId, objectTypeId, objectId) => {
  return {
    associationTypeId: ENGAGEMENT_ASSOCIATION_ID_MAP[objectTypeId],
    fromObjectId: engagementId,
    toObjectId: objectId,
    associationCategory: 'HUBSPOT_DEFINED'
  };
};
export const buildRemoveAssociationUrlParts = (engagementId, objectTypeId, objectId) => {
  return `${engagementId}/${ENGAGEMENT_ASSOCIATION_TYPE_MAP[objectTypeId]}/${objectId}`;
};