import { OVERLAY_NONE } from '../../Constants';
import { OVERLAY_CLOSE_MODAL, OVERLAY_OPEN_MODAL } from '../actionTypes/ActionTypes';
const initialState = {
  modalType: OVERLAY_NONE,
  modalData: {}
};
export default ((state = initialState, action) => {
  switch (action.type) {
    case OVERLAY_OPEN_MODAL:
      {
        const {
          modalType,
          modalData
        } = action.payload;
        return Object.assign({}, state, {
          modalType,
          modalData
        });
      }
    case OVERLAY_CLOSE_MODAL:
      {
        return Object.assign({}, state, {
          modalType: OVERLAY_NONE,
          modalData: {}
        });
      }
    default:
      {
        return state;
      }
  }
});