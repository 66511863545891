import ClassicLightTheme from '../../themes/classic/light/theme';
const LOCAL_STORAGE_THEME_KEY = 'trellis:theme';
const themeNameMapping = {
  canvas: 'classic',
  trellis: 'next'
};
const toInternalThemeName = themeName => {
  return themeNameMapping[themeName] || themeName;
};
export const getDefaultTheme = () => {
  return ClassicLightTheme;
};
export const getTheme = async (themeName, themeMode) => {
  if (themeName === 'classic') {
    if (themeMode === 'light') {
      return Promise.resolve(ClassicLightTheme);
    }
  } else if (themeName === 'pink') {
    if (themeMode === 'light') {
      return (await import('./mock-themes/pink/light/theme')).default;
    }
  } else if (themeName === 'next') {
    if (themeMode === 'light') {
      return (await import('../../themes/next/light/theme')).default;
    } else if (themeMode === 'dark') {
      return (await import('../../themes/next/dark/theme')).default;
    }
  }
  return undefined;
};
export const getLocalThemeOverride = async () => {
  try {
    const value = localStorage.getItem(LOCAL_STORAGE_THEME_KEY);
    if (!value) {
      return undefined;
    }
    const [themeName, themeMode] = value.split('-');
    return await getTheme(toInternalThemeName(themeName), themeMode);
  } catch (err) {
    return undefined;
  }
};
export const getThemeToProvide = async (themeName, themeMode) => {
  let theme = await getLocalThemeOverride();
  if (theme) {
    return theme;
  }
  theme = await getTheme(themeName, themeMode);
  if (theme) {
    return theme;
  }
  throw new Error('Unsupported theme and mode combination.');
};