import I18n from 'I18n';
import once from 'transmute/once';
import { OrderedMap, fromJS } from 'immutable';
import { createActivityBundle } from 'activity-feed-ui/records/ActivityBundleRecord';
const emailClick = () => createActivityBundle(fromJS({
  onboardingData: {
    disabled: true,
    illustrationName: 'avatar-jane'
  },
  latestActivityForOrigin: {
    activityOriginDetail: {
      vid: 1,
      firstName: 'Charlotte',
      lastName: 'Walker',
      companyName: 'Rocketsalt',
      jobTitle: I18n.text('onboarding.activities.emailClickJobTitle'),
      email: null
    },
    createdAt: Date.now(),
    activityOriginType: 'CONTACT',
    activityType: 'EMAIL_CLICK',
    activityOriginId: '1',
    activitySource: {
      deviceType: 'COMPUTER'
    },
    activityMeta: {
      link: 'https://www.hubspot.com',
      linkText: I18n.text('onboarding.activities.emailClickedLink'),
      emailSubject: null,
      emailLink: ''
    }
  },
  count: 1
}));
const emailOpen = () => createActivityBundle(fromJS({
  onboardingData: {
    disabled: true,
    illustrationName: 'avatar-hugo'
  },
  latestActivityForOrigin: {
    createdAt: Date.now(),
    activityOriginDetail: {
      vid: 1,
      firstName: 'Aaron',
      lastName: 'Rios',
      companyName: 'Steelworth Metalworks, Inc.',
      jobTitle: 'CEO',
      email: null
    },
    activityOriginId: '1',
    activityOriginType: 'CONTACT',
    activityType: 'EMAIL_OPEN',
    activitySource: {
      deviceType: 'COMPUTER'
    },
    activityMeta: {
      emailSubject: I18n.text('onboarding.activities.emailOpenSubjectLine')
    }
  },
  count: 1
}));
const pageVisit = () => createActivityBundle(fromJS({
  onboardingData: {
    disabled: true,
    illustrationName: 'avatar-michelle'
  },
  latestActivityForOrigin: {
    activityOriginDetail: {
      vid: 1,
      firstName: 'Ruby',
      lastName: 'Kelley',
      companyName: 'Padlock Security',
      jobTitle: I18n.text('onboarding.activities.pageVisitJobTitle'),
      email: null
    },
    createdAt: Date.now(),
    activityOriginType: 'CONTACT',
    activityType: 'HUBSPOT_REVISIT',
    activityOriginId: '1',
    activitySource: {
      deviceType: 'COMPUTER'
    },
    activityMeta: {
      page: I18n.text('onboarding.activities.pageVisit'),
      pageLink: ''
    }
  },
  count: 1
}));
const getOnboardingActivities = () => OrderedMap({
  EMAIL_CLICK: emailClick(),
  EMAIL_OPEN: emailOpen(),
  PAGE_VISIT: pageVisit()
});
export default once(getOnboardingActivities);