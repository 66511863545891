import { APP_INIT, SET_DISMISS_ONBOARDING, SET_DISMISS_SAMPLE_ACTIVITY } from 'activity-feed-ui/redux/actionTypes/ActionTypes';
const initialState = {
  dismissOnboarding: false,
  dismissSampleActivity: true
};
export default ((state = initialState, action) => {
  switch (action.type) {
    case APP_INIT:
      {
        const {
          dismissOnboarding,
          dismissSampleActivity
        } = action.payload.userAttributes;
        return Object.assign({}, state, {
          dismissOnboarding,
          dismissSampleActivity
        });
      }
    case SET_DISMISS_ONBOARDING:
      return Object.assign({}, state, {
        dismissOnboarding: action.payload
      });
    case SET_DISMISS_SAMPLE_ACTIVITY:
      return Object.assign({}, state, {
        dismissSampleActivity: action.payload
      });
    default:
      return state;
  }
});