import { APP_INIT } from 'activity-feed-ui/redux/actionTypes/ActionTypes';
import UserContainer from 'activity-feed-ui/dataContainers/UserContainer';
const initialState = {
  user: null,
  ownerId: null
};
export default ((state = initialState, action) => {
  switch (action.type) {
    case APP_INIT:
      {
        const {
          ownerId
        } = action.payload;
        return Object.assign({}, state, {
          ownerId,
          user: UserContainer.get()
        });
      }
    default:
      return state;
  }
});