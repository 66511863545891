import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
// Export a function so we can create stores in unit/integration tests.
export const createStore = () => configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false
  })
});

// This is the store we use throughout the real app
export const store = createStore();
export const useDispatch = useReduxDispatch;
export function useSelector(selector) {
  return useReduxSelector(selector);
}