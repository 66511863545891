import { getIconNamePropType } from '../utils/propTypes/iconName';
import PropTypes from 'prop-types';
export const SingleValueType = PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]);
export const ValueType = PropTypes.oneOfType([SingleValueType, PropTypes.arrayOf(SingleValueType)]);
export const OptionType = PropTypes.shape({
  avatar: PropTypes.node,
  badge: PropTypes.object,
  buttonText: PropTypes.node,
  'data-test-id': PropTypes.string,
  disabled: PropTypes.bool,
  dropdownClassName: PropTypes.string,
  dropdownText: PropTypes.node,
  icon: getIconNamePropType(),
  iconColor: PropTypes.string,
  help: PropTypes.node,
  imageUrl: PropTypes.string,
  loading: PropTypes.bool,
  tag: PropTypes.object,
  tagUse: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  value: SingleValueType
});
export const OptionGroupType = PropTypes.shape({
  dropdownClassName: PropTypes.string,
  dropdownText: PropTypes.node,
  emptyMessage: PropTypes.string,
  options: PropTypes.arrayOf(OptionType.isRequired).isRequired,
  text: PropTypes.string,
  title: PropTypes.string
});
export const OptionOrGroupType = PropTypes.oneOfType([OptionGroupType, OptionType]);
export function isOptionGroup(option) {
  return option.options !== undefined;
}