import { OVERLAY_CLOSE_MODAL, OVERLAY_OPEN_MODAL } from '../actionTypes/ActionTypes';
export const openModalAction = (modalType, modalData = {}) => dispatch => {
  dispatch({
    type: OVERLAY_OPEN_MODAL,
    payload: {
      modalType,
      modalData
    }
  });
};
export const closeModalAction = () => dispatch => {
  dispatch({
    type: OVERLAY_CLOSE_MODAL
  });
};