'use es6';

export default function (lookupObj) {
  const {
    avatarType,
    displayName,
    domain,
    email,
    lookup,
    primaryIdentifier,
    secondaryIdentifier,
    src
  } = lookupObj;
  const proxyLookup = lookup || {};
  const display = displayName || proxyLookup.displayName || email || proxyLookup.email || domain || proxyLookup.domain || typeof primaryIdentifier !== 'number' && primaryIdentifier || typeof proxyLookup.primaryIdentifier !== 'number' && proxyLookup.primaryIdentifier || secondaryIdentifier || proxyLookup.secondaryIdentifier || src || proxyLookup.src || avatarType && avatarType.toUpperCase() || undefined;
  return display;
}