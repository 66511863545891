import { Record } from 'immutable';
const defaultActivitySourceValues = {
  name: '',
  email: '',
  userAgent: '',
  ip: '',
  link: '',
  domain: '',
  isExact: false,
  deviceType: null,
  timestamp: null
};
const ActivitySourceRecord = Record(defaultActivitySourceValues, 'ActivitySourceRecord');
export default ActivitySourceRecord;