import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["q", "filter", "contactProperty"];
import { parse } from 'hub-http/helpers/params';
const SUPPORTED_PARAMS = ['searchQuery', 'activityType', 'enrolledStatus', 'source',
// Deprecated
'q', 'filter', 'contactProperty'];
function getRawQueryParams() {
  return parse(window.location.search.replace('?', ''));
}

/*
Some query params get renamed over time as the product evolves in
order to make it more clear what they mean. In order to try and save
existing links that are bookmarked we can attempt to transform them to
their updated versions here. */

function transformDeprecatedParams(_ref) {
  let {
      q,
      filter,
      contactProperty
    } = _ref,
    params = _objectWithoutPropertiesLoose(_ref, _excluded);
  if (q) {
    params.searchQuery = q;
  }
  if (filter) {
    params.activityType = filter;
  }
  if (contactProperty) {
    params.enrolledStatus = contactProperty;
  }
  return params;
}
export function getQueryParams() {
  const params = transformDeprecatedParams(getRawQueryParams());
  const queryParams = Object.fromEntries(Object.entries(params).filter(([key]) => {
    return SUPPORTED_PARAMS.includes(key);
  }));
  return queryParams;
}