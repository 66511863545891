'use es6';

import PropTypes from 'prop-types';
export default PropTypes.oneOfType([PropTypes.func,
// A React element type (eg. MyComponent).
// a function, string, or "element-like" object (eg. React.Fragment, Suspense, etc.)
// see https://github.com/facebook/react/blob/master/packages/shared/isValidElementType.js
PropTypes.shape({
  current: PropTypes.elementType
}),
// ref on a DOM element
PropTypes.shape({
  current: PropTypes.instanceOf(Element)
})]);