import { UserAttributesClient } from 'frontend-preferences-client';
import { fromJS } from 'immutable';
const userAttributesClient = UserAttributesClient.forCaller('activity-feed-ui');
export default {
  fetchAttributes(keys) {
    return userAttributesClient.batchFetch(keys).then(fromJS);
  },
  saveAttribute(key, value) {
    return userAttributesClient.write(key, `${value}`);
  }
};