import events from 'activity-feed-ui/events.yaml';
import { createTracker } from 'usage-tracker';
import { ACTIVITY_EVENT, ONBOARDING_EVENT, ONBOARDING_VIEW, PAGE_LOAD, SEARCHED_FEED } from './Events';
const tracker = createTracker({
  events,
  properties: {
    namespace: 'activity-feed-ui'
  },
  lastKnownEventProperties: ['screen', 'subscreen']
});
const trackActivityEvent = (action, {
  subAction,
  activityType
} = {}) => {
  tracker.track(ACTIVITY_EVENT, {
    action,
    subAction,
    activityType
  });
};
const trackPageLoad = ({
  type,
  source
}) => {
  tracker.track(PAGE_LOAD, {
    type,
    screen: type,
    subscreen: source
  });
};
const trackSearchedFeed = ({
  activityTypeFilter
}) => {
  tracker.track(SEARCHED_FEED, {
    activityTypeFilter
  });
};
const trackOnboardingEvent = ({
  action,
  onboardingScreen
}) => {
  tracker.track(ONBOARDING_EVENT, {
    action,
    onboardingScreen
  });
};
const trackOnboardingView = ({
  type
}) => {
  tracker.track(ONBOARDING_VIEW, {
    type
  });
};
export default {
  trackActivityEvent,
  tracker,
  trackOnboardingEvent,
  trackOnboardingView,
  trackSearchedFeed,
  trackPageLoad
};