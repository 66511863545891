import PortalIdParser from 'PortalIdParser';
import userInfo from 'hub-http/userInfo';
import { isInternal, getUserId } from 'activity-feed-ui/utils/internalUtils';
import { get } from './HubSpotClient';
export const fetchAlarms = () => {
  const portalId = PortalIdParser.get();
  return userInfo().then(info => {
    const userId = isInternal() ? getUserId() : info.user.user_id;
    return get(`firealarm/v4/alarm/activity-feed-ui/${portalId}`, {
      headers: {
        'X-Origin-UserId': userId
      }
    });
  });
};