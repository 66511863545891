import { APP_INIT, ACTIVITY_FEED_SEARCH, ACTIVITY_FEED_SEARCH_SUCCESS, SET_HAS_RECEIVED_MORE_ACTIVITIES } from 'activity-feed-ui/redux/actionTypes/ActionTypes';
const initialState = {
  mostRecentActivity: null,
  hasReceivedMoreActivities: false
};
export default ((state = initialState, action) => {
  switch (action.type) {
    case ACTIVITY_FEED_SEARCH:
      return initialState;
    case APP_INIT:
      {
        const {
          earlyRequestData
        } = action.payload;
        if (!earlyRequestData) {
          return state;
        }
        const results = earlyRequestData.get('results');
        return Object.assign({}, state, {
          mostRecentActivity: results.isEmpty() ? null : results.first()
        });
      }
    case ACTIVITY_FEED_SEARCH_SUCCESS:
      {
        const {
          results,
          originalOffset
        } = action.payload;
        if (originalOffset !== 0) {
          return state;
        }
        return Object.assign({}, state, {
          mostRecentActivity: results.isEmpty() ? null : results.first(),
          hasReceivedMoreActivities: false
        });
      }
    case SET_HAS_RECEIVED_MORE_ACTIVITIES:
      return Object.assign({}, state, {
        hasReceivedMoreActivities: true
      });
    default:
      return state;
  }
});