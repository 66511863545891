import userInfo from 'hub-http/userInfo';
import Raven from 'raven-js';
export default (isEmbedded => userInfo({
  ignoreRedirect: isEmbedded
}).catch(err => {
  Raven.captureException(err, {
    extra: {
      functionName: 'initHubSpotUser',
      isEmbedded
    }
  });
  return null;
}));