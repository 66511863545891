import { EMBEDDED_PATH, WEB_APP_PATH } from 'activity-feed-ui/constants/RootPaths';
import PortalIdParser from 'PortalIdParser';
function addOrigin(shouldAdd, path) {
  if (!shouldAdd) {
    return path;
  }
  return `${window.location.origin}${path}`;
}
export const getRootUrl = isEmbedded => {
  const baseURL = isEmbedded ? EMBEDDED_PATH : WEB_APP_PATH;
  return `/${baseURL}/${PortalIdParser.get()}`;
};
export const getSettingsUrl = (includeOrigin = false) => {
  // settings page only exists on web app
  const path = `/notification-preferences/${PortalIdParser.get()}/global-preferences`;
  return addOrigin(includeOrigin, path);
};