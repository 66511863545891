'use es6';

import { PORTAL_SETTINGS_FETCH, PORTAL_SETTINGS_FETCH_FAILED, PORTAL_SETTINGS_FETCH_SUCCEEDED } from './ActionTypes';
import { getAllPortalSettings } from 'sales-clients-common/apis/CursusApi';
const portalSettingFetchStart = () => ({
  type: PORTAL_SETTINGS_FETCH
});
const portalSettingsFetchSucceeded = portalSettings => ({
  type: PORTAL_SETTINGS_FETCH_SUCCEEDED,
  payload: portalSettings
});
const portalSettingsFetchFailed = error => ({
  type: PORTAL_SETTINGS_FETCH_FAILED,
  payload: error
});

/**
 * @typedef {import("sales-clients-common/apis/CursusApi").ImmutablePromiseClient} ImmutablePromiseClient
 * @param {ImmutablePromiseClient} apiClient
 * @param {*} portalId
 */
export const fetch = (apiClient, portalId) => dispatch => {
  dispatch(portalSettingFetchStart());
  if (!portalId) {
    return dispatch(portalSettingsFetchFailed(new Error('Missing portal id')));
  }
  return getAllPortalSettings(apiClient, portalId).then(portalSettings => dispatch(portalSettingsFetchSucceeded(portalSettings))).catch(error => dispatch(portalSettingsFetchFailed(error)));
};