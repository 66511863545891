import Raven from 'raven-js';
import identity from 'transmute/identity';
// @ts-expect-error Untyped
import createAction from 'flux-actions/createAction';
import { rethrowError } from 'UIComponents/core/PromiseHandlers';
import AttributesApi from 'activity-feed-ui/api/AttributesApi';
import AttributeKeys from 'activity-feed-ui/constants/AttributeKeys';
import { SET_DISMISS_ONBOARDING, SET_DISMISS_SAMPLE_ACTIVITY } from 'activity-feed-ui/redux/actionTypes/ActionTypes';
const setDismissOnboarding = createAction(SET_DISMISS_ONBOARDING, identity);
const setDismissSampleActivity = createAction(SET_DISMISS_SAMPLE_ACTIVITY, identity);
export const hideOnboarding = () => dispatch => {
  AttributesApi.saveAttribute(AttributeKeys.DismissOnboarding, true).then(() => {
    dispatch(setDismissOnboarding(true));
  }).catch(err => {
    dispatch(setDismissOnboarding(true));
    Raven.captureException(err);
  });
};
export const hideSampleActivity = () => dispatch => {
  AttributesApi.saveAttribute(AttributeKeys.DismissSampleActivity, true).then(() => {
    dispatch(setDismissSampleActivity(true));
  }).catch(err => {
    dispatch(setDismissSampleActivity(true));
    rethrowError(err);
  });
};