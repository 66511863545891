'use es6';

export const debounce = (func, wait, immediate) => {
  let timeout;
  return function (...arg) {
    const context = this;
    const args = arg;
    const later = function later() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};
export const once = function once(func, n = 2) {
  let result;
  if (typeof func !== 'function') {
    throw new TypeError('Expected a function in avatars once');
  }
  return function (...args) {
    if (--n > 0) {
      result = func.apply(this, args);
    }
    if (n <= 1) {
      func = undefined;
    }
    return result;
  };
};