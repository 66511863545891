'use es6';

/**
 * Maintain unique namespace for sales-clients-common
 * @param {string} actionType
 */
const namespaceActionType = actionType => `sales-clients-common:${actionType}`;
const createAsyncAction = actionType => ({
  start: namespaceActionType(actionType),
  succeeded: namespaceActionType(`${actionType}_SUCCEEDED`),
  failed: namespaceActionType(`${actionType}_FAILED`)
});
const portalSettingsAsync = createAsyncAction('PORTAL_SETTINGS_FETCH');
export const PORTAL_SETTINGS_FETCH = portalSettingsAsync.start;
export const PORTAL_SETTINGS_FETCH_SUCCEEDED = portalSettingsAsync.succeeded;
export const PORTAL_SETTINGS_FETCH_FAILED = portalSettingsAsync.failed;

//#region NeverlogPopover
export const NEVERLOG_POPOVER_OPEN = namespaceActionType('NEVERLOG_POPOVER_OPEN');
export const NEVERLOG_POPOVER_DISMISS = namespaceActionType('NEVERLOG_POPOVER_DISMISS');
export const NEVERLOG_POPOVER_CLOSE_COMPLETE = namespaceActionType('NEVERLOG_POPOVER_CLOSE_COMPLETE');
export const neverlogAddEmailAsync = createAsyncAction('NEVERLOG_POPOVER_ADD_EMAIL');
export const RECEIVED_AVAILABLE_ACCOUNTS = namespaceActionType('RECEIVED_AVAILABLE_ACCOUNTS');
export const RECEIVED_ACCOUNTS_SALES_ACCESS = namespaceActionType('RECEIVED_ACCOUNTS_SALES_ACCESS');
//#endregion

//#region MeetingsPopover
export const MEETINGS_SET_IS_ACTIVE = namespaceActionType('MEETINGS_SET_IS_ACTIVE');
export const MEETINGS_SET_CALENDAR_CONNECTED = namespaceActionType('MEETINGS_SET_CALENDAR_CONNECTED');
export const MEETINGS_UPDATE_LINKS = namespaceActionType('MEETINGS_UPDATE_LINKS');
export const MEETINGS_UPDATE_TEAMMATES = namespaceActionType('MEETINGS_UPDATE_TEAMMATES');
export const meetingsInitAsync = createAsyncAction('MEETINGS_INIT');
//#endregion