'use es6';

let quickFetch = window.quickFetch;
if (!window.quickFetch) {
  console.warn('[quick-fetch] quickFetchScript was not included. Falling back to stubbed implementation.');
  quickFetch = {
    afterAuth() {},
    clearAllRequests() {},
    getApiUrl() {},
    getPortalId() {},
    getRequestStateByName() {
      return null;
    },
    makeEarlyRequest() {},
    makeLoginVerifyRequest() {},
    removeEarlyRequest() {},
    getCookie() {
      return null;
    }
  };
}
export default quickFetch;