export const EMAIL_TRACKING_ENABLED = 'EMAIL_TRACKING_ENABLED';
export const SALES_EXTENSION_EMAIL_LOGGING_LEVEL = 'SALES_EXTENSION_EMAIL_LOGGING_LEVEL';
export const SALES_EXTENSION_EMAIL_TRACKING_LEVEL = 'SALES_EXTENSION_EMAIL_TRACKING_LEVEL';
export const SALES_EXTENSION_DEFAULT_ASSOCIATIONS_ENABLED = 'SALES_EXTENSION_DEFAULT_ASSOCIATIONS_ENABLED';
export const SALES_EXTENSION_DEFAULT_ASSOCIATIONS_FOR_EXISTING_OBJECTS = 'SALES_EXTENSION_DEFAULT_ASSOCIATIONS_FOR_EXISTING_OBJECTS';
export const SALES_EXTENSION_DEFAULT_ASSOCIATIONS_FOR_NEW_OBJECTS = 'SALES_EXTENSION_DEFAULT_ASSOCIATIONS_FOR_NEW_OBJECTS';
export const ExtLogAndTrackLevel = {
  USER_CONTROLLED: 'USER_CONTROLLED',
  SET_BY_ADMINISTRATOR_ENABLED: 'SET_BY_ADMINISTRATOR_ENABLED',
  SET_BY_ADMINISTRATOR_DISABLED: 'SET_BY_ADMINISTRATOR_DISABLED'
};