import { Record } from 'immutable';
import ActivityTypes from 'activity-feed-ui/constants/ActivityTypes';
import FilterTypes from 'activity-feed-ui/constants/FilterTypes';
import { ActivityTypeFilterValues } from 'activity-feed-ui/constants/Filters';
const defaultActivityTypeFilterValues = {
  type: FilterTypes.ACTIVITY_TYPE,
  value: ActivityTypeFilterValues.ALL,
  options: Object.values(ActivityTypeFilterValues)
};
const ActivityTypeFilterFactory = Record(defaultActivityTypeFilterValues, 'ActivityTypeFilterRecord');
export default class ActivityTypeFilterRecord extends ActivityTypeFilterFactory {
  isDefaultValue() {
    return this.value === ActivityTypeFilterValues.ALL;
  }
  setValue(newValue) {
    return this.set('value', newValue);
  }
  toQuery(filters) {
    if (this.value === ActivityTypeFilterValues.ALL) {
      return filters.update('activityFeedFilters', activityFeedFilters => activityFeedFilters.push({
        field: 'activityType',
        values: Object.values(ActivityTypes)
      }));
    }
    return filters.update('activityFeedFilters', activityFeedFilters => activityFeedFilters.push({
      field: 'activityType',
      values: [this.value]
    }));
  }
  toQueryParams(queryParams) {
    return Object.assign({}, queryParams, {
      activityType: this.value === ActivityTypeFilterValues.ALL ? undefined : this.value
    });
  }
}