import ActivityFeedApi from '../api/ActivityFeedApi';
import { hasActivityAddOnsScope } from './permissions';
import { rethrowError } from 'UIComponents/core/PromiseHandlers';
const getAddOnsRequest = activityResult => {
  const domains = [];
  const contactIds = [];
  const companyIds = [];
  activityResult.forEach(activity => {
    if (activity) {
      const activityOriginType = activity.getOriginType();
      const activityOriginId = activity.getOriginId();
      if (activityOriginType === 'CONTACT') {
        contactIds.push(Number(activityOriginId));
        const associatedCompanyId = activity.getOriginAssociatedCompanyId();
        if (associatedCompanyId) {
          companyIds.push(Number(associatedCompanyId));
        }
      } else if (activityOriginType === 'COMPANY') {
        companyIds.push(Number(activityOriginId));
      } else if (activityOriginType === 'COMPANY_PROSPECT') {
        domains.push(`${activityOriginId}`);
      }
    }
  });
  return {
    domains,
    contactIds,
    companyIds
  };
};
export const fetchAddOns = results => {
  if (hasActivityAddOnsScope()) {
    const addOnsRequest = getAddOnsRequest(results);
    return ActivityFeedApi.addOnSearch(addOnsRequest).catch(error => {
      // 404 and 403 are expected errors. 404: Most likely a JITA user. 403: User does not have the correct permissions.
      if (error.status !== 403 && error.status !== 404) {
        rethrowError(error);
      }
    });
  } else {
    return Promise.resolve();
  }
};