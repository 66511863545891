import { hasActivityAddOnsScope } from '../../lib/permissions';
import SourceTypes from '../../constants/SourceTypes';
const activityTypeMap = {
  CONTACT: 'addOnsByContactId',
  COMPANY: 'addOnsByCompanyId',
  COMPANY_PROSPECT: 'addOnsByDomain'
};
export const addOnsSelector = state => state.addOns;
const isSupportedActivityOriginType = activityOriginType => {
  return Object.values(SourceTypes).includes(activityOriginType);
};
export const activityTypeKeySelector = activity => {
  if (!isSupportedActivityOriginType(activity.activityOriginType)) {
    return null;
  }
  return activity.activityOriginType ? activityTypeMap[activity.activityOriginType] : null;
};
export const isTargetAccountSelector = (addOns, activityOriginId, activityTypeKey, associatedCompanyId) => {
  if (!hasActivityAddOnsScope() || !activityTypeKey) {
    return false;
  }
  if (activityTypeKey === 'addOnsByContactId') {
    return Boolean(associatedCompanyId && addOns.addOnsByCompanyId[associatedCompanyId] && addOns.addOnsByCompanyId[associatedCompanyId].isTargetAccount);
  } else {
    const addonData = addOns[activityTypeKey][activityOriginId];
    return Boolean(addonData && addonData.isTargetAccount);
  }
};