import { Record, Map as ImmutableMap } from 'immutable';
import { EMAIL_TRACKING_ENABLED, SALES_EXTENSION_EMAIL_LOGGING_LEVEL, SALES_EXTENSION_EMAIL_TRACKING_LEVEL, ExtLogAndTrackLevel } from 'sales-clients-common/constants/CursusPortalSettingsKeys';
export default Record({
  settings: ImmutableMap({
    [EMAIL_TRACKING_ENABLED]: true,
    [SALES_EXTENSION_EMAIL_LOGGING_LEVEL]: ExtLogAndTrackLevel.USER_CONTROLLED,
    [SALES_EXTENSION_EMAIL_TRACKING_LEVEL]: ExtLogAndTrackLevel.USER_CONTROLLED
  }),
  isLoading: true,
  lastError: null
}, 'PortalSettingsDataRecord');