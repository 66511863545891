import I18n from 'I18n';
import { OrderedSet, Map as ImmutableMap, fromJS } from 'immutable';
import EmailProviderTypes from 'activity-feed-ui/constants/EmailProviderTypes';
import { getInteractionUrl, getComposeInGmailUrl, getComposeInOutlookUrl } from 'activity-feed-ui/lib/Links';
import { shouldAllowMutingForActivityType } from '../utils/mutingHidingUtils';
import { getUniqueKey } from '../utils/activityOriginUtils';
const toI18nText = name => I18n.text(`notificationActionButtons.${name}`);
const createLinkActionButton = (recipient, name, includeInteraction = true) => ({
  type: name,
  name: toI18nText(name),
  href: getInteractionUrl(recipient, includeInteraction ? name : null)
});
export const createMuteNotificationButton = (onMuteThreadNotifications, originUniqueKey) => ImmutableMap({
  type: 'muteNotification',
  name: I18n.text('notificationActionButtons.muteNotification'),
  onClick: () => onMuteThreadNotifications(originUniqueKey)
});
export const createUnmuteNotificationButton = (onUnmuteThreadNotifications, originUniqueKey) => ImmutableMap({
  type: 'unmuteNotification',
  name: I18n.text('notificationActionButtons.unmuteNotification'),
  onClick: () => onUnmuteThreadNotifications(originUniqueKey)
});
export const createDeleteActivityBundleButton = (onDeleteActivityBundle, originUniqueKey) => ImmutableMap({
  type: 'deleteActivityBundle',
  name: I18n.text('notificationActionButtons.deleteActivityBundle'),
  onClick: () => onDeleteActivityBundle(originUniqueKey)
});
export const _createEmailInInboxButton = (inbox, recipient) => {
  if (!inbox || inbox.isEmpty()) {
    return undefined;
  }
  const contactEmail = recipient.get('email');
  const inboxType = inbox.get('inboxType');
  switch (inboxType) {
    case EmailProviderTypes.GOOGLE_FREE:
    case EmailProviderTypes.GOOGLE_APPS:
      return ImmutableMap({
        type: 'emailInGmail',
        name: I18n.text('notificationActionButtons.emailInGmail'),
        href: getComposeInGmailUrl(contactEmail)
      });
    case EmailProviderTypes.OUTLOOK365:
      return ImmutableMap({
        type: 'emailInOutlook',
        name: I18n.text('notificationActionButtons.emailInOutlook'),
        href: getComposeInOutlookUrl(contactEmail)
      });
    default:
      return undefined;
  }
};
export const getContactActionButtons = ({
  activityType,
  inbox,
  isMuted,
  onDeleteActivityBundle,
  onMuteThreadNotifications,
  onUnmuteThreadNotifications,
  origin,
  originUniqueKey,
  useCondensedView
}) => {
  let buttons = fromJS([createLinkActionButton(origin, 'call'), createLinkActionButton(origin, 'email'), createLinkActionButton(origin, 'task'), createLinkActionButton(origin, 'note'), createLinkActionButton(origin, 'schedule')]);
  const emailInInboxButton = _createEmailInInboxButton(inbox, origin);
  if (emailInInboxButton) {
    buttons = buttons.push(emailInInboxButton);
  }
  if (shouldAllowMutingForActivityType(activityType)) {
    if (isMuted) {
      buttons = buttons.push(createUnmuteNotificationButton(onUnmuteThreadNotifications, originUniqueKey));
    } else {
      buttons = buttons.push(createMuteNotificationButton(onMuteThreadNotifications, originUniqueKey));
    }
  }

  // Only allow deleting in main activity feed, not embedded
  if (!useCondensedView) {
    buttons = buttons.push(createDeleteActivityBundleButton(onDeleteActivityBundle, originUniqueKey));
  }
  return OrderedSet(buttons);
};
export const getNotContactActionButtons = ({
  activityType,
  createContact,
  inbox,
  isMuted,
  onDeleteActivityBundle,
  onMuteThreadNotifications,
  onUnmuteThreadNotifications,
  origin,
  originUniqueKey,
  useCondensedView
}) => {
  if (!origin || !origin.email) {
    return OrderedSet();
  }
  let buttons = fromJS([{
    type: 'addContact',
    name: I18n.text('notificationActionButtons.addContact'),
    onClick: () => createContact(origin)
  }]);
  const emailInInboxButton = _createEmailInInboxButton(inbox, origin);
  if (emailInInboxButton) {
    buttons = buttons.push(emailInInboxButton);
  }
  if (shouldAllowMutingForActivityType(activityType)) {
    if (isMuted) {
      buttons = buttons.push(createUnmuteNotificationButton(onUnmuteThreadNotifications, originUniqueKey));
    } else {
      buttons = buttons.push(createMuteNotificationButton(onMuteThreadNotifications, originUniqueKey));
    }
  }

  // Only allow deleting in main activity feed, not embedded
  if (!useCondensedView) {
    buttons = buttons.push(createDeleteActivityBundleButton(onDeleteActivityBundle, originUniqueKey));
  }
  return OrderedSet(buttons);
};
export const getNoOriginActionButtons = ({
  activityType,
  isMuted,
  onDeleteActivityBundle,
  onMuteThreadNotifications,
  onUnmuteThreadNotifications,
  originUniqueKey,
  useCondensedView
}) => {
  let buttons = fromJS([]);
  if (shouldAllowMutingForActivityType(activityType)) {
    if (isMuted) {
      buttons = buttons.push(createUnmuteNotificationButton(onUnmuteThreadNotifications, originUniqueKey));
    } else {
      buttons = buttons.push(createMuteNotificationButton(onMuteThreadNotifications, originUniqueKey));
    }
  }

  // Only allow deleting in main activity feed, not embedded
  if (!useCondensedView) {
    buttons = buttons.push(createDeleteActivityBundleButton(onDeleteActivityBundle, originUniqueKey));
  }
  return OrderedSet(buttons);
};
export function getActionButtons({
  activityOriginId,
  activityOriginType,
  activityType,
  createContact,
  isMuted,
  onDeleteActivityBundle,
  onMuteThreadNotifications,
  onUnmuteThreadNotifications,
  inbox,
  origin,
  useCondensedView
}) {
  // Unique key is used to locate the correct activity bundle for mute/unmute
  // Should be same as origin.getUniqueKey but for activities where origin is null ('Someone' activities), have to construct
  // the key separately from activityOriginId and activityOriginType
  const originUniqueKey = getUniqueKey(activityOriginType, activityOriginId);
  if (origin && origin.isContact()) {
    return getContactActionButtons({
      activityType,
      isMuted,
      onDeleteActivityBundle,
      onMuteThreadNotifications,
      onUnmuteThreadNotifications,
      inbox,
      origin,
      originUniqueKey,
      useCondensedView
    });
  }
  if (origin && origin.email) {
    return getNotContactActionButtons({
      activityType,
      createContact,
      isMuted,
      onDeleteActivityBundle,
      onMuteThreadNotifications,
      onUnmuteThreadNotifications,
      inbox,
      origin,
      originUniqueKey,
      useCondensedView
    });
  }
  if (activityOriginId && activityOriginType) {
    return getNoOriginActionButtons({
      activityType,
      isMuted,
      onDeleteActivityBundle,
      onMuteThreadNotifications,
      onUnmuteThreadNotifications,
      originUniqueKey,
      useCondensedView
    });
  }
  return OrderedSet();
}