import Raven from 'raven-js';
import PortalIdParser from 'PortalIdParser';
import { get } from './HubSpotClient';
import { fromJS, Map as ImmutableMap } from 'immutable';
import { isInternal, getUserId } from 'activity-feed-ui/utils/internalUtils';
const connectedAccountsSuccess = data => {
  if (!Array.isArray(data) || data.length === 0) {
    return ImmutableMap();
  }
  return fromJS(data).reduceRight((inbox, connectedAccountData) => {
    const connectedAccount = connectedAccountData.get('features').find(feature => feature.get('featureType') === 'FACSIMILE_INBOX');
    const connectedAccountInbox = connectedAccount ? connectedAccount.get('inbox') : inbox;
    return connectedAccountInbox || ImmutableMap();
  }, ImmutableMap());
};
const connectedAccountsFailure = err => {
  if (!err || err.status !== 0) {
    Raven.captureMessage(`fetchInbox error: ${err.status}`, {
      extra: err
    });
  }
  return null;
};
export default {
  fetchInbox() {
    const portal = PortalIdParser.get();
    const baseUrl = 'connectedaccounts/v1/accounts';
    const url = isInternal() ? `${baseUrl}/portal/${portal}/user/${getUserId()}` : baseUrl;
    return get(url).then(connectedAccountsSuccess, connectedAccountsFailure);
  }
};