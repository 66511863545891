import SourceTypes from 'activity-feed-ui/constants/SourceTypes';
import TaskTypes from 'activity-feed-ui/constants/TaskTypes';
import enviro from 'enviro';
import { stringify } from 'hub-http/helpers/params';
import { getFullUrl } from 'hubspot-url-utils';
import I18n from 'I18n';
import PortalIdParser from 'PortalIdParser';
const portalId = PortalIdParser.get();
function getBaseHubspotUrl() {
  const subdomain = enviro.deployed() ? 'app' : 'local';
  return getFullUrl(subdomain);
}
export const getOriginUrl = ({
  originId,
  originType
}) => {
  if (originId && originType === SourceTypes.CONTACT) {
    return `${getBaseHubspotUrl()}/contacts/${portalId}/contact/${originId}/`;
  }
  if (originId && originType === SourceTypes.COMPANY) {
    return `${getBaseHubspotUrl()}/contacts/${portalId}/company/${originId}/`;
  }
  return null;
};
export const getContactsUrl = contactId => {
  return `${getBaseHubspotUrl()}/contacts/${portalId}/contact/${contactId}/`;
};
export const getCompaniesUrl = companyId => {
  return `${getBaseHubspotUrl()}/contacts/${portalId}/company/${companyId}/`;
};
export const getAddMeetingNoteUrl = contactId => {
  return `${getBaseHubspotUrl()}/contacts/${portalId}/contact/${contactId}/?interaction=activity`;
};
export const getMeetingsAppLink = () => {
  return `${getBaseHubspotUrl()}/meetings/${portalId}`;
};
export const getInteractionUrl = (recipient, interaction) => {
  const recipientVid = recipient.get('activityOriginId');
  return `${getContactsUrl(recipientVid)}${interaction ? `?interaction=${interaction}` : ''}`;
};
export const getComposeInGmailUrl = contactEmail => `https://mail.google.com/mail/?view=cm&fs=1&to=${contactEmail}`;
export const getComposeInOutlookUrl = contactEmail => `https://outlook.office.com/?path=/mail/action/compose&to=${contactEmail}`;
export const getSendToCoolRobotInOutlookUrl = () => {
  const subject = I18n.text('onboarding.coolrobotEmail.subject');
  const body = I18n.text('onboarding.coolrobotEmail.body');
  return `https://outlook.office.com/?path=/mail/action/compose&to=coolrobot@hubspot.com&subject=${subject}&body=${body}`;
};
export const getOutlookUrl = () => 'https://outlook.office.com/';
export const getGmailOnboarding = email => `https://mail.google.com/mail/u/?hubspot_sales_onboarding=INAPP_ACTIVITY_STREAM&authuser=${email}`;
export const getFormLink = formId => `${getBaseHubspotUrl()}/forms/${portalId}/${formId}/submissions`;
export const getConnectInbox = () => `${getBaseHubspotUrl()}/settings/${portalId}/user-preferences/email?isWizardOpen=true`;
export const getHubSpotLogin = () => `${getBaseHubspotUrl()}/login`;
export const getCreateAccountUrl = () => `${getBaseHubspotUrl()}/signup/sales/join-hubspot`;
export const getScopeRedirectUrl = () => `${getBaseHubspotUrl()}/myaccounts`;
export const getAccountSettings = () => `${getBaseHubspotUrl()}/settings/${portalId}/user-preferences/email?selectAccount=true`;
export const getTaskQueueUrl = ({
  title,
  taskId,
  taskType,
  nextTaskOffset,
  redirectUrl
}) => {
  const params = {
    title,
    taskId,
    nextTaskOffset,
    redirectUrl
  };
  if (taskType) {
    params.interaction = taskType !== TaskTypes.TODO ? taskType.toLowerCase() : '';
  }
  return `/contacts/${portalId}/tasks-queue/?${stringify(params)}`;
};
export const getTaskTypeUrl = ({
  type,
  ownerId
}) => {
  return `/contacts/${portalId}/tasks/list/view/due_today?${stringify({
    type,
    ownerId
  })}`;
};
export const getNotificationSettings = () => `/user-preferences/${portalId}/global-preferences`;
export const getProspectingWorkspaceTimelineUrl = queryParams => {
  const queryParamsString = stringify(queryParams);
  return queryParamsString ? `/prospecting/${portalId}/activity-feed?${queryParamsString}` : `/prospecting/${portalId}/activity-feed`;
};
export const activityFeed = `${getBaseHubspotUrl()}/activity-feed/${portalId}/all`;
export const downloadChrome = 'https://www.google.com/chrome/';
export const privacyPolicy = 'https://legal.hubspot.com/privacy-policy';
export const tasks = `/contacts/${portalId}/tasks`;
export const coolRobotContact = `/contacts/${portalId}/contact/1/?interaction=email`;
export const someoneInfoArticle = 'https://knowledge.hubspot.com/articles/kcs_article/email-notifications/what-does-a-someone-notification-mean';
export const leadRevisitArticle = 'https://knowledge.hubspot.com/articles/kcs_article/prospects/use-prospects';
export const salesNotificationArticle = 'https://knowledge.hubspot.com/email-notifications/view-your-sales-notifications-in-the-activity-feed';