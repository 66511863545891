'use es6';

import * as ApiNames from './ApiNames';
import { ADS, BREEZE_INTELLIGENCE_CREDITS_100, BREEZE_INTELLIGENCE_CREDITS_1000, BREEZE_INTELLIGENCE_CREDITS_10000, BREEZE_INTELLIGENCE_CREDITS_10000_COMMITTED, BREEZE_INTELLIGENCE_CREDITS_1000_COMMITTED, BREEZE_INTELLIGENCE_CREDITS_100_COMMITTED, BREEZE_INTELLIGENCE_OVERAGE_CREDITS_FOR_100, BREEZE_INTELLIGENCE_OVERAGE_CREDITS_FOR_1000, BREEZE_INTELLIGENCE_OVERAGE_CREDITS_FOR_10000, CALCULATED_PROPERTIES_LIMIT_INCREASE, CALLING_LIMIT_INCREASE, CMS_ENTERPRISE, CMS_PROFESSIONAL, CMS_STARTER, CORE_SEATS_ENTERPRISE, CORE_SEATS_PROFESSIONAL, CORE_SEATS_STARTER, CRM_CONTACT_RECORDS_LIMIT_INCREASE, CRM_OBJECT_LIMIT_INCREASE, CUSTOM_OBJECTS_LIMIT_INCREASE, CUSTOM_PROPERTIES_LIMIT_INCREASE, CUSTOM_SSL, DEDICATED_IP, DOMAINS_LIMIT_INCREASE, ESIGNATURES_LIMIT_INCREASE, ESIGNATURE_LIMIT_INCREASE, MARKETING_ENTERPRISE, MARKETING_PLUS_ENTERPRISE, MARKETING_PLUS_PROFESSIONAL, MARKETING_PRO, MARKETING_SMS_ADDON, MARKETING_SMS_SHORT_CODE, MARKETING_STARTER, MARKETING_STARTER_EMAIL, OPERATIONS_ENTERPRISE, OPERATIONS_PROFESSIONAL, OPERATIONS_STARTER, PHONE_LIMIT_INCREASE, REPORTING, SALES_ENTERPRISE, SALES_PROFESSIONAL, SALES_STARTER, SANDBOX_LIMIT_INCREASE, SERVICE_ENTERPRISE, SERVICE_PROFESSIONAL, SERVICE_STARTER, STARTER_CONTACTS, SUITE_ENTERPRISE, SUITE_PROFESSIONAL, SUITE_STARTER, TEAMS_LIMIT_INCREASE, VIDEO_LIMIT_INCREASE, WHATSAPP_LIMIT_INCREASE, WORKFLOWS_ADDON } from './UpgradeProducts';

/**
 * @note keep in sync with ApiNameToUpgradeProductMap
 */
const UpgradeProductToApiNameMap = {
  [SALES_STARTER]: ApiNames.SALES_HUB_STARTER,
  [SALES_PROFESSIONAL]: ApiNames.SALES_HUB_PROFESSIONAL,
  [SALES_ENTERPRISE]: ApiNames.SALES_HUB_ENTERPRISE,
  [MARKETING_STARTER_EMAIL]: ApiNames.MARKETING_HUB_STARTER,
  [MARKETING_STARTER]: ApiNames.MARKETING_HUB_STARTER,
  [MARKETING_PRO]: ApiNames.MARKETING_HUB_PROFESSIONAL,
  [MARKETING_ENTERPRISE]: ApiNames.MARKETING_HUB_ENTERPRISE,
  [SERVICE_STARTER]: ApiNames.SERVICE_HUB_STARTER,
  [SERVICE_PROFESSIONAL]: ApiNames.SERVICE_HUB_PROFESSIONAL,
  [SERVICE_ENTERPRISE]: ApiNames.SERVICE_HUB_ENTERPRISE,
  [CMS_STARTER]: ApiNames.CMS_HUB_STARTER,
  [CMS_PROFESSIONAL]: ApiNames.CMS_HUB_PROFESSIONAL,
  [CMS_ENTERPRISE]: ApiNames.CMS_HUB_ENTERPRISE,
  [OPERATIONS_STARTER]: ApiNames.OPERATIONS_HUB_STARTER,
  [OPERATIONS_PROFESSIONAL]: ApiNames.OPERATIONS_HUB_PROFESSIONAL,
  [OPERATIONS_ENTERPRISE]: ApiNames.OPERATIONS_HUB_ENTERPRISE,
  [CORE_SEATS_STARTER]: ApiNames.CORE_SEATS_STARTER,
  [CORE_SEATS_PROFESSIONAL]: ApiNames.CORE_SEATS_PROFESSIONAL,
  [CORE_SEATS_ENTERPRISE]: ApiNames.CORE_SEATS_ENTERPRISE,
  [SUITE_STARTER]: ApiNames.CRM_SUITE_STARTER_BUNDLE,
  [SUITE_PROFESSIONAL]: ApiNames.CRM_SUITE_PROFESSIONAL_BUNDLE,
  [SUITE_ENTERPRISE]: ApiNames.CRM_SUITE_ENTERPRISE_BUNDLE,
  [MARKETING_PLUS_PROFESSIONAL]: ApiNames.MARKETING_PLUS_PROFESSIONAL_BUNDLE,
  [MARKETING_PLUS_ENTERPRISE]: ApiNames.MARKETING_PLUS_ENTERPRISE_BUNDLE,
  [ADS]: ApiNames.ADS_LIMIT_INCREASE,
  [CUSTOM_OBJECTS_LIMIT_INCREASE]: ApiNames.CUSTOM_OBJECTS_LIMIT_INCREASE,
  [DOMAINS_LIMIT_INCREASE]: ApiNames.DOMAINS_LIMIT_INCREASE,
  [SANDBOX_LIMIT_INCREASE]: ApiNames.SANDBOX_LIMIT_INCREASE,
  [ESIGNATURES_LIMIT_INCREASE]: ApiNames.ESIGNATURES_LIMIT_INCREASE,
  [ESIGNATURE_LIMIT_INCREASE]: ApiNames.ESIGNATURE_LIMIT_INCREASE,
  [CALLING_LIMIT_INCREASE]: ApiNames.CALLING_LIMIT_INCREASE,
  [PHONE_LIMIT_INCREASE]: ApiNames.PHONE_LIMIT_INCREASE,
  [TEAMS_LIMIT_INCREASE]: ApiNames.TEAMS_LIMIT_INCREASE,
  [WHATSAPP_LIMIT_INCREASE]: ApiNames.WHATSAPP_LIMIT_INCREASE,
  [VIDEO_LIMIT_INCREASE]: ApiNames.VIDEO_LIMIT_INCREASE,
  [CALCULATED_PROPERTIES_LIMIT_INCREASE]: ApiNames.CALCULATED_PROPERTIES_LIMIT_INCREASE,
  [CUSTOM_PROPERTIES_LIMIT_INCREASE]: ApiNames.CUSTOM_PROPERTIES_LIMIT_INCREASE,
  [CRM_OBJECT_LIMIT_INCREASE]: ApiNames.CRM_OBJECT_LIMIT_INCREASE,
  [WORKFLOWS_ADDON]: ApiNames.WORKFLOWS_LIMIT_INCREASE,
  [MARKETING_SMS_ADDON]: ApiNames.MARKETING_SMS_ADDON,
  [MARKETING_SMS_SHORT_CODE]: ApiNames.MARKETING_SMS_SHORT_CODE,
  [CRM_CONTACT_RECORDS_LIMIT_INCREASE]: ApiNames.CRM_CONTACT_RECORDS_LIMIT_INCREASE,
  [REPORTING]: ApiNames.REPORTING_LIMIT_INCREASE,
  [STARTER_CONTACTS]: ApiNames.MARKETING_HUB_STARTER,
  [CUSTOM_SSL]: ApiNames.CUSTOM_SSL,
  [DEDICATED_IP]: ApiNames.DEDICATED_IP,
  [BREEZE_INTELLIGENCE_CREDITS_100]: ApiNames.BREEZE_INTELLIGENCE_CREDITS_100,
  [BREEZE_INTELLIGENCE_CREDITS_1000]: ApiNames.BREEZE_INTELLIGENCE_CREDITS_1000,
  [BREEZE_INTELLIGENCE_CREDITS_10000]: ApiNames.BREEZE_INTELLIGENCE_CREDITS_10000,
  [BREEZE_INTELLIGENCE_CREDITS_100_COMMITTED]: ApiNames.BREEZE_INTELLIGENCE_CREDITS_100_COMMITTED,
  [BREEZE_INTELLIGENCE_CREDITS_1000_COMMITTED]: ApiNames.BREEZE_INTELLIGENCE_CREDITS_1000_COMMITTED,
  [BREEZE_INTELLIGENCE_CREDITS_10000_COMMITTED]: ApiNames.BREEZE_INTELLIGENCE_CREDITS_10000_COMMITTED,
  [BREEZE_INTELLIGENCE_OVERAGE_CREDITS_FOR_100]: ApiNames.BREEZE_INTELLIGENCE_OVERAGE_CREDITS_FOR_100,
  [BREEZE_INTELLIGENCE_OVERAGE_CREDITS_FOR_1000]: ApiNames.BREEZE_INTELLIGENCE_OVERAGE_CREDITS_FOR_1000,
  [BREEZE_INTELLIGENCE_OVERAGE_CREDITS_FOR_10000]: ApiNames.BREEZE_INTELLIGENCE_OVERAGE_CREDITS_FOR_10000
};
export default UpgradeProductToApiNameMap;