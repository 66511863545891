'use es6';

import UUID from '../utils/UUID';
export const MSG_FORMAT_HS_IFR = '__hs-ifr';
export const MSG_FORMAT_VERSION = 1;
export const TO_ALL = 'ALL';
export const isOfType = (message, type) => message.payload.type === type;
export const validFormat = ({
  format,
  v
} = {}) => format === MSG_FORMAT_HS_IFR && v > 0 && v <= MSG_FORMAT_VERSION;
export const createMessage = (type, data, {
  to,
  from
}) => ({
  id: UUID(),
  format: MSG_FORMAT_HS_IFR,
  from,
  to,
  v: MSG_FORMAT_VERSION,
  payload: Object.assign({
    type
  }, data)
});
export const createReplyMessage = (replyToMessage, type, data) => Object.assign({}, createMessage(type, data, {
  from: replyToMessage.to,
  to: replyToMessage.from
}), {
  id: replyToMessage.id
});
export const createDirectedMessage = (type, data, {
  to,
  from
}) => createMessage(type, data, {
  to,
  from
});
export const createDirectedReplyMessage = createReplyMessage;
export const createBroadcastMessage = (type, data, {
  from,
  group
}) => Object.assign({}, createMessage(type, data, {
  to: TO_ALL,
  from
}), {
  group
});
export const createBroadcastReplyMessage = (replyToMessage, type, data, from) => Object.assign({}, createReplyMessage(replyToMessage, type, data), {
  from
});
export const isBroadcastMessageToMyGroup = (message, group) => message.to === TO_ALL && message.group === group;
export const isMessageToMe = (message, myId, myGroup) => message.to === myId || isBroadcastMessageToMyGroup(message, myGroup);