import valueSeq from 'transmute/valueSeq';
import ActivityTypes from 'activity-feed-ui/constants/ActivityTypes';
export default function ({
  activityOriginType,
  activityOriginId
}) {
  const filters = [{
    field: 'activityType',
    values: valueSeq(ActivityTypes).toArray()
  }];
  if (activityOriginType) {
    filters.push({
      field: 'activityOriginType',
      values: [activityOriginType]
    });
  }
  if (activityOriginId) {
    filters.push({
      field: 'activityOriginId',
      values: [activityOriginId]
    });
  }
  return filters;
}