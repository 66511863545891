export const ActivityEventActions = {
  NewActivityRendered: 'new-activity-rendered',
  DismissActivity: 'dismiss-activity',
  ClickedContactRecord: 'clicked-contact-record',
  ActionButtonClick: 'action-button-click'
};
export const OnboardingActions = {
  ConnectInbox: 'clicked-connect-inbox',
  DownloadExtension: 'clicked-download-extension',
  SwitchBrowser: 'clicked-switch-browser'
};
export const PageLoadFeed = {
  OLD: 'old',
  V4: 'v4'
};