import { Record, List, Map as ImmutableMap } from 'immutable';
import { BundledActivityLimit } from 'activity-feed-ui/Constants';
import ActivityTypes from 'activity-feed-ui/constants/ActivityTypes';
import ActivityRecord from './ActivityRecord';
import { getUniqueKey as getUniqueKeyUtil } from '../utils/activityOriginUtils';
const TYPES_WITHOUT_THREAD = [ActivityTypes.HUBSPOT_REVISIT, ActivityTypes.COMPANYPROSPECTS_REVISIT, ActivityTypes.PRESENTATION_REVISIT, ActivityTypes.MEETING_BOOKED, ActivityTypes.FORM_SUBMITTED];
const defaultActivityBundleValues = {
  count: 0,
  latestActivityForOrigin: new ActivityRecord(),
  allActivities: List(),
  isFetching: false,
  offset: 0,
  hasMore: false,
  triedFetchingFooterActivities: false,
  onboardingData: null
};
const ActivityBundleFactory = Record(defaultActivityBundleValues, 'ActivityBundle');
export class ActivityBundleRecord extends ActivityBundleFactory {
  getAllActivities() {
    if (this.allActivities.isEmpty() && this.getPrimaryActivity()) {
      return List([this.getPrimaryActivity()]);
    }
    return this.allActivities;
  }
  updateOriginWithContact(contact) {
    return this.withMutations(updatedBundle => updatedBundle.update('latestActivityForOrigin', activity => activity.updateOriginWithContact(contact)).update('allActivities', activities => activities.map(activity => activity.updateOriginWithContact(contact))));
  }
  updateActivityMutedStatus(threadId, muted) {
    return this.withMutations(updatedBundle => updatedBundle.update('latestActivityForOrigin', activity => activity.updateActivityMutedStatus(threadId, muted)).update('allActivities', activities => activities.map(activity => activity.updateActivityMutedStatus(threadId, muted))));
  }
  shouldFetchInitialActivities() {
    return !this.triedFetchingFooterActivities && this.allActivities.isEmpty();
  }
  getNumToFetchNext() {
    const numNotFetched = this.getTotalActivities() - this.allActivities.size;
    return numNotFetched > BundledActivityLimit ? BundledActivityLimit : numNotFetched;
  }
  hasMultipleActivities() {
    return this.count > 1;
  }
  getTotalActivities() {
    return this.count;
  }
  getPrimaryActivity() {
    return this.latestActivityForOrigin;
  }
  getOrigin() {
    return this.getPrimaryActivity().getOrigin();
  }
  getOriginId() {
    return this.getPrimaryActivity().activityOriginId;
  }
  getOriginAssociatedCompanyId() {
    return this.getPrimaryActivity().activityOriginDetail ? this.getPrimaryActivity().activityOriginDetail.associatedCompanyId : null;
  }
  getOriginType() {
    return this.getPrimaryActivity().activityOriginType;
  }
  getTimestamp() {
    return this.getPrimaryActivity().getTimestamp();
  }

  /*
   * Returns the thread counts with the primary activity added if it is not in a thread.j
   * We should only use this internally for getting thread counts in getThreadCounts or getTotalActivitiesInThread */
  _getThreadCountWithExtras() {
    const {
      activityThreadCount,
      activityType
    } = this.getPrimaryActivity();
    if (!activityThreadCount) {
      return ImmutableMap();
    }

    // if the top activity is one that isn't always in a thread, fake it
    if (TYPES_WITHOUT_THREAD.includes(activityType) && !activityThreadCount.has(activityType)) {
      return activityThreadCount.set(activityType, 1);
    }
    return activityThreadCount;
  }
  getThreadCounts() {
    const {
      activityType
    } = this.getPrimaryActivity();
    const activityThreadCount = this._getThreadCountWithExtras();

    // show 1 sent if the top level activity is a sent
    if (activityType === ActivityTypes.EMAIL_TRACKER_CREATE) {
      return activityThreadCount.set(ActivityTypes.EMAIL_TRACKER_CREATE, 1);
    }
    return activityThreadCount.remove(ActivityTypes.EMAIL_TRACKER_CREATE);
  }
  getTotalActivitiesInThread() {
    const activityThreadCount = this._getThreadCountWithExtras();
    return activityThreadCount.reduce((total, numActivitiesOfType) => {
      return total + numActivitiesOfType;
    }, 0);
  }
  isEqual(activityBundleRecord) {
    const {
      count,
      latestActivityForOrigin
    } = activityBundleRecord;
    return count === this.count && this.latestActivityForOrigin && latestActivityForOrigin && this.latestActivityForOrigin.isEqual(latestActivityForOrigin);
  }
  getUniqueKey() {
    return getUniqueKeyUtil(this.getOriginType(), this.getOriginId());
  }
  addActivities({
    activities,
    hasMore,
    offset
  }) {
    return this.withMutations(bundle => bundle.update('allActivities', allActivities => allActivities.concat(activities)).merge({
      triedFetchingFooterActivities: true,
      isFetching: false,
      hasMore,
      offset
    }));
  }
}
export function createActivityBundle(data) {
  const bundle = new ActivityBundleRecord(data).merge({
    latestActivityForOrigin: ActivityRecord.create(data.get('latestActivityForOrigin'))
  });
  // hasMore is only initialized here, its later changed based on api results
  return bundle.set('hasMore', bundle.hasMultipleActivities());
}