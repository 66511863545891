import { generatePersistedQueries, parseChirpGraphQLResponse } from './chirp';
import { buildHttpRpcPath } from './internal';
import { readUnhandledEnumCase } from './types';
import { makeChirpAvroCall, parseIntoUnion, parseRejectingChirpErrors } from './chirp-avro';

// these imports must be in this order for mocking reasons
export { defaultDismissErrorWithoutHandling } from './internal';
import { defaultDismissErrorWithoutHandling } from '.';
export { makeChirpEarlyRequest } from './chirp-quick-fetch';
import { getChirpEarlyRequest } from './chirp-quick-fetch';
export { readUnhandledEnumCase };
export { formatChirpMSWInternalErrorResponse, formatChirpMSWUserErrorResponse, formatChirpMSWSuccessResponse, formatChirpAvroMSWSuccessResponse, formatChirpAvroMSWUserErrorResponse, formatChirpAvroMSWInternalErrorResponse } from './chirp';
/** @deprecated prefer createRpcClientV2 */
export function createRpcClientV1(options) {
  const httpRpcClient = createHttpRpcClientV1(options);
  const chirpRpcClient = createChirpRpcClientV1(options);
  function executeRemoteProcedure(details, inputs) {
    if (details.method) {
      return httpRpcClient.call(details, inputs);
    } else {
      return chirpRpcClient.call(details, inputs);
    }
  }
  return {
    call: executeRemoteProcedure
  };
}
export function createHttpRpcClientV2({
  hubHttpClient
}) {
  function executeRemoteProcedure(details, {
    pathParameters,
    queryParameters,
    data
  }) {
    const path = buildHttpRpcPath(details, pathParameters);
    const syncStackError = new Error(`HTTP RPC failed for ${details.method}-/${path}`);
    let options = undefined;
    if (data) {
      options = options || {};
      options.data = data;
    }
    if (queryParameters) {
      options = options || {};
      options.query = queryParameters;
    }
    const apiPromise = hubHttpClient[details.method](path, options);
    return apiPromise.catch(error => {
      syncStackError.cause = error;
      throw syncStackError;
    });
  }
  return {
    call: executeRemoteProcedure
  };
}

/**
 * This client factory takes a `hub-http` client and returns a client that
 * executes network requests as RPCs.
 *
 * An RPC (Remote Procedure Call) is a network request where the details of the
 * network have been abstracted away. In other words, RPCs are calls to typed
 * async functions that make network requests under the hood. The goal is to
 * minimize time spent thinking about network details (HTTP verbs, url
 * sanitization, CHIRP query names, etc.).
 *
 * This factory is suffixed "V2" because it is guaranteed to never change in a
 * breaking way. If features incompatible with this client factory are added
 * in the future, this client factory will remain available and a new "V3" will
 * be exposed as a sibling for new consumers.
 *
 * ```
 * // kudos service contains generated code
 * import { getKudos } from 'kudos-service/client';
 * import { createRpcClientV1 } from 'rpc-client-utils';
 *
 * const rpcClient = createRpcClientV1({ hubHttpClient: hubHttpClient });
 *
 * const kudos = await rpcClient.call(getKudos, {
 *   // depending on the RPC, data and pathParameters may also be passed
 *   queryParameters: {
 *     hubspotter: 'jdoe'
 *   }
 * });
 * ```
 */
export function createRpcClientV2(options) {
  const httpRpcClient = createHttpRpcClientV2(options);
  const chirpRpcClient = createChirpRpcClientV2(options);
  const chirpAvroRpcClient = createChirpAvroRpcClientV1(options);
  function executeRemoteProcedure(details, inputs) {
    if (details.method) {
      return httpRpcClient.call(details, inputs);
    } else if ('gateway' in details) {
      return chirpRpcClient.call(details, inputs);
    } else {
      return chirpAvroRpcClient.call(details, inputs);
    }
  }
  function callWithResultUnion(details, inputs) {
    if (details.method || 'gateway' in details) {
      throw new Error('callWithResultUnion is not implemented for non-avro RPCs.');
    }
    return chirpAvroRpcClient.callWithResultUnion(details, inputs);
  }
  return {
    call: executeRemoteProcedure,
    callWithResultUnion
  };
}
/** @deprecated prefer createHttpRpcClientV2 */
export function createHttpRpcClientV1({
  hubHttpClient,
  dismissErrorWithoutHandling = error => defaultDismissErrorWithoutHandling(error)
}) {
  function executeRemoteProcedure(details, {
    pathParameters,
    queryParameters,
    data
  }) {
    const path = buildHttpRpcPath(details, pathParameters);
    const syncStackError = new Error(`HTTP RPC failed for ${details.method}-/${path}`);
    let options = undefined;
    if (data) {
      options = options || {};
      options.data = data;
    }
    if (queryParameters) {
      options = options || {};
      options.query = queryParameters;
    }
    const apiPromise = hubHttpClient[details.method](path, options);
    return apiPromise.catch(error => {
      dismissErrorWithoutHandling(syncStackError);
      throw error;
    });
  }
  return {
    call: executeRemoteProcedure
  };
}

/** @deprecated prefer createChirpRpcClientV2 */
export function createChirpRpcClientV1({
  hubHttpClient,
  dismissErrorWithoutHandling = error => defaultDismissErrorWithoutHandling(error)
}) {
  function executeRemoteProcedure(details, {
    request
  }) {
    const syncStackError = new Error(`CHIRP RPC failed for ${details.name}`);
    const {
      gateway,
      name,
      query
    } = details;
    const {
      chirpGraphQLGatewayEndpoint,
      data
    } = generatePersistedQueries({
      chirpGraphQLGatewayEndpoint: gateway,
      operationName: name,
      query,
      variables: {
        request
      }
    });
    return hubHttpClient.post(chirpGraphQLGatewayEndpoint, {
      data
    }).then(response => parseChirpGraphQLResponse(response)).catch(error => {
      dismissErrorWithoutHandling(syncStackError);
      throw error;
    });
  }
  return {
    call: executeRemoteProcedure
  };
}
export function createChirpRpcClientV2({
  hubHttpClient
}) {
  function executeRemoteProcedure(details, {
    request
  }) {
    const syncStackError = new Error(`CHIRP RPC failed for ${details.name}`);
    const {
      gateway,
      name,
      query
    } = details;
    const {
      chirpGraphQLGatewayEndpoint,
      data
    } = generatePersistedQueries({
      chirpGraphQLGatewayEndpoint: gateway,
      operationName: name,
      query,
      variables: {
        request
      }
    });
    return hubHttpClient.post(chirpGraphQLGatewayEndpoint, {
      data
    }).then(response => parseChirpGraphQLResponse(response)).catch(error => {
      syncStackError.cause = error;
      throw syncStackError;
    });
  }
  return {
    call: executeRemoteProcedure
  };
}
export function createChirpAvroRpcClientV1({
  hubHttpClient
}) {
  function call(details, inputs) {
    return makeChirpAvroCall({
      hubHttpClient,
      details,
      responseParser: parseRejectingChirpErrors,
      inputs,
      alternateData: getChirpEarlyRequest({
        details,
        requestObject: inputs
      })
    });
  }
  function callWithResultUnion(details, inputs) {
    return makeChirpAvroCall({
      hubHttpClient,
      details,
      responseParser: parseIntoUnion,
      inputs,
      alternateData: getChirpEarlyRequest({
        details,
        requestObject: inputs
      })
    });
  }
  return {
    call,
    callWithResultUnion
  };
}