/* hs-eslint ignored failing-rules */

'use es6';

import { debounce, once } from './lib/utils';
import * as Atom from './lib/Atom';
import { fetchAvatars } from './api/AvatarAPI';
import defaultFetchAvatars from './api/defaultFetchAvatars';
import { Map as ImmutableMap, Set as ImmutableSet, List } from 'immutable';
import invariant from 'react-utils/invariant';
import PortalIdParser from 'PortalIdParser';
import LookupRecord from './Records/LookupRecord';
export const FIRST_TRY_DELAY = 200;
export const SECOND_TRY_DELAY = 1000;
export const LAST_TRY_DELAY = 4000;

/**
 * Given the various state Atoms, retrieves a URI from the cache or adds the
 * lookup to the queue.
 *
 * @param  {Atom<Map<LookupRecord,?string>>}
 * @param  {Atom<Set<LookupRecord>>}
 * @param  {Function}
 * @param  {Function}
 * @param  {number}
 * @param  {Lookup}
 * @return {?LookupRecord}
 */
export function getAvatarURI(cacheAtom, pendingAtom, enqueue, fetcher, portalId, lookup) {
  invariant(typeof fetcher === 'function', 'expected `fetcher` to be a function but got `%s`', fetcher);
  invariant(typeof portalId === 'number' || !lookup.requiresPortalId(), 'expected `portalId` to be a number or the lookup to not require a portalId but got `%s`', portalId);
  invariant(lookup instanceof LookupRecord, 'expected `lookup` to be a LookupRecord but got `%s`', lookup);
  const avatars = Atom.deref(cacheAtom);
  if (avatars.has(lookup)) {
    return avatars.get(lookup);
  }
  if (!Atom.deref(pendingAtom).has(lookup)) {
    enqueue(fetcher, portalId, ImmutableSet.of(lookup));
  }
  return undefined;
}

/**
 * @param  {?Map<LookupRecord, ?string>}
 * @return {Atom<Map<LookupRecord, ?string>>}
 */
export function makeCacheAtom(initialCache = ImmutableMap()) {
  return Atom.atom(initialCache, ImmutableMap.isMap);
}

/**
 * @param  {?Set<LookupRecord>}
 * @return {Atom<Set<LookupRecord>>}
 */
export function makeQueueAtom(initialQueue = ImmutableSet()) {
  return Atom.atom(initialQueue, ImmutableSet.isSet);
}

/**
 * Adds lookups to the queue and schedules a queue flush.
 *
 * @param  {Atom<Set<LookupRecord>>}
 * @param  {Function}
 * @param  {Function}
 * @param  {number}
 * @param  {Set<LookupRecord>}
 * @return {void}
 */
export function queuer(queueAtom, flushQueue, fetcher, portalId, lookups) {
  invariant(ImmutableSet.isSet(lookups) && !lookups.isEmpty(), 'expected `lookups` to be a non-empty Set but got `%s`', lookups);
  Atom.swap(queueAtom, current => current.union(lookups));
  flushQueue(fetcher, portalId);
}

/**
 * Creates a queing function bound to a queue Atom.
 *
 * @param  {Function}
 * @param  {Function}
 * @param  {Atom<Map<LookupRecord,?string>>}
 * @param  {Atom<Set<LookupRecord>>}
 * @param  {Function}
 * @return {Function}
 */
export function makeQueuer(queueAtom, doEnqueue, doFlush, cacheAtom, pendingAtom, nextEnqueue) {
  const flushQueue = (...partialArgs) => doFlush(cacheAtom, queueAtom, pendingAtom, nextEnqueue, ...partialArgs);
  return (...partialArgs) => doEnqueue(queueAtom, flushQueue, ...partialArgs);
}

/**
 * Splits lookups into buckets based on their dimensions so the fetcher can make
 * separate calls for each dimension.
 *
 * @param  {Set<LookupRecord>}
 * @return {Map<?number, Set(LookupRecord)>}
 */
export function toDimensionBuckets(lookups) {
  return lookups.reduce((dimensionBuckets, lookup) => {
    return dimensionBuckets.update(lookup.dimensions, maybeSet => {
      return typeof maybeSet === 'undefined' ? ImmutableSet.of(lookup) : maybeSet.add(lookup);
    });
  }, ImmutableMap());
}

/**
 *  Splits the lookup set into batches in order so the fetcher can make
 *  batch requests to Avatars
 *
 * @param  {Set<LookupRecord>}
 * @param  {number}
 * @return {List<Set<LookupRecord>>}
 */
export function toBatches(lookupSet, batchSize = 200) {
  invariant(typeof batchSize === 'number' && batchSize > 0, 'expected `batchSize` to number greater than 0 but got `%s`', batchSize);
  return lookupSet.reduce((batches, lookup) => {
    if (batches.last().size >= batchSize) {
      return batches.push(ImmutableSet.of(lookup));
    }
    return batches.set(-1, batches.last().add(lookup));
  }, List.of(ImmutableSet()));
}

/**
 * Try to resolve the contents of a queue, adding hits to the cache and pushing
 * retries to the next queue.
 *
 * @param  {Atom<Map<LookupRecord,?string>>}
 * @param  {Atom<Set<LookupRecord>>}
 * @param  {Atom<Set<LookupRecord>>}
 * @param  {Function}
 * @param  {Function}
 * @param  {number}
 * @return {void}
 */
export function flush(cacheAtom, queueAtom, pendingAtom, nextFlush, fetcher, portalId) {
  const lookups = Atom.deref(queueAtom);
  if (lookups.isEmpty()) {
    return;
  }
  Atom.swap(pendingAtom, current => current.union(lookups));
  Atom.swap(queueAtom, current => current.clear());
  toDimensionBuckets(lookups).forEach((lookupSet, dimensions) => {
    toBatches(lookupSet).forEach(batch => {
      fetchAvatars(fetcher, portalId, batch, dimensions).then(({
        retry,
        updates
      }) => {
        Atom.swap(pendingAtom, current => current.subtract(lookups));
        Atom.swap(cacheAtom, current => current.merge(updates));
        if (nextFlush && !retry.isEmpty()) {
          nextFlush(fetcher, portalId, retry);
        }
      }).catch(err => {
        setTimeout(() => {
          throw err;
        });
      });
    });
  });
}

/**
 * Factory that creates the various state Atoms and bound functions that compose
 * an AvatarResolver and cache.
 *
 * @return {Object}
 */
export function makeAvatarResolver() {
  const cacheAtom = makeCacheAtom();
  const pendingAtom = makeQueueAtom();
  const firstQueueAtom = makeQueueAtom();
  const secondQueueAtom = makeQueueAtom();
  const lastQueueAtom = makeQueueAtom();
  const enqueueLastTry = makeQueuer(lastQueueAtom, queuer, debounce(flush, LAST_TRY_DELAY), cacheAtom, pendingAtom);
  const enqueueSecondTry = makeQueuer(secondQueueAtom, queuer, debounce(flush, SECOND_TRY_DELAY), cacheAtom, pendingAtom, enqueueLastTry);
  const enqueueFirstTry = makeQueuer(firstQueueAtom, queuer, debounce(flush, FIRST_TRY_DELAY), cacheAtom, pendingAtom, enqueueSecondTry);
  return {
    /**
     * The Atom that stores the lookup => url cache. It's usually best to use
     * the helper methods (getAvatarURI, setAvatarURI, etc.) rather than using
     * the cache directly.
     *
     * @prop  {Atom<Map<LookupRecord,?string>>}
     */
    avatars: cacheAtom,
    /**
     * Returns an avatar uri from the cache or queues the lookup to be fetched.
     *
     * @param  {LookupRecord}
     * @param  {Function}       fetcher that returns a promise for the API response
     * @param  {number}         portal id
     * @return {?string}        avatar url
     */
    getAvatarURI(lookup, fetcher = defaultFetchAvatars, portalId = PortalIdParser.get()) {
      return getAvatarURI(cacheAtom, pendingAtom, enqueueFirstTry, fetcher, portalId, lookup);
    },
    /**
     * Associate an avatar uri with a lookup in the cache. This is useful when
     * client interaction changes the image an avatar should display (i.e. the
     * user uploaded a new profile photo for a contact).
     *
     * @param  {LookupRecord}   lookup (email or domain)
     * @param  {?string}        new url or null
     * @param  {Function}       fetcher that returns a promise for the API response
     * @param  {number}         portal id
     * @return {?string}        avatar url
     */
    setAvatarURI(lookup, value) {
      const key = lookup;
      invariant(key instanceof LookupRecord, 'expected `lookup` to be an instance of LookupRecord but got `%s`', lookup);
      invariant(value === null || typeof value === 'string' && value.length > 0, 'expected `value` to be a non-empty string or `null` but got `%s`', value);
      Atom.swap(cacheAtom, current => {
        return current.set(lookup, value).map((cachedValue, cachedLookup) => {
          return lookup.isSameAs(cachedLookup) ? value : cachedValue;
        });
      });
    },
    /**
     * Register a callback to be triggered whenever the avatars caches changes.
     *
     * @param  {Function}  callback
     * @return void
     */
    watch: (...partialArgs) => Atom.watch(cacheAtom, ...partialArgs),
    /**
     * Unregister a callback that was previously registered with `watch`.
     *
     * @param  {Function}  callback
     * @return void
     */
    unwatch: (...partialArgs) => Atom.unwatch(cacheAtom, ...partialArgs)
  };
}

/**
 * Returns the AvatarResolver singleton used by UIAvatar.
 *
 * @return {Object}
 */
export const getAvatarResolver = once(makeAvatarResolver);