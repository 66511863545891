module.exports = {
  "/": {
    "success": {
      "default": [
        "ACTIVITY_FEED_LOAD"
      ]
    },
    "error": [
      "ACTIVITY_FEED_FAIL"
    ]
  },
  "/all": {
    "success": {
      "default": [
        "ACTIVITY_FEED_LOAD"
      ]
    },
    "error": [
      "ACTIVITY_FEED_FAIL"
    ]
  },
  "/timeline": {
    "success": {
      "default": [
        "ACTIVITY_FEED_LOAD"
      ]
    },
    "error": [
      "ACTIVITY_FEED_FAIL"
    ]
  },
  "/settings": {
    "name": "settings",
    "success": {
      "default": [
        "SETTINGS_LOAD"
      ]
    }
  },
  "/embedded-settings": {
    "success": {
      "default": [
        "EMBEDDED_SETTINGS_LOAD"
      ]
    }
  }
};