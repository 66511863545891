'use es6';

import { createStack } from 'hub-http';
import { header } from 'hub-http/middlewares/core';
import { logoutOn } from './hubSpotter';
import { CSRF_HEADER, getCsrfCookieValue } from '../helpers/csrf';
export const logoutOnMissingCsrf = options => logoutOn(() => !getCsrfCookieValue(options))(options);
export const withCsrf = options => {
  const csrfCookie = getCsrfCookieValue(options);
  return csrfCookie ? header(CSRF_HEADER, csrfCookie)(options) : options;
};
export default createStack(logoutOnMissingCsrf, withCsrf);