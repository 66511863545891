export default {
  get(key) {
    try {
      const value = localStorage.getItem(key);
      // Only parse if we got a string. If the value is falsy, just return it.
      if (value) {
        return JSON.parse(value);
      }
      return value;
    } catch (err) {
      return null;
    }
  },
  set(key, value) {
    try {
      localStorage.setItem(key, value);
    } catch (err) {
      console.error(err);
    }
  }
};