'use es6';

import quickFetch from 'quick-fetch';
import formatActivityResults from 'activity-feed-ui/lib/formatActivityResults';
export default function (fallbackFetch, requestName) {
  const earlyRequest = quickFetch.getRequestStateByName(requestName);
  if (earlyRequest) {
    return new Promise((resolve, reject) => {
      earlyRequest.whenFinished(result => {
        resolve(formatActivityResults(result));
      });
      earlyRequest.onError(() => fallbackFetch().then(result => resolve(formatActivityResults(result)), (err, ...args) => reject(err, ...args)));
    });
  }
  return fallbackFetch();
}