'use es6';

import { redirectTo } from 'hub-http/helpers/location';
import * as params from 'hub-http/helpers/params';
import { responseError } from 'hub-http/helpers/response';
import { buildUrl, parseUrl } from 'hub-http/helpers/url';
import { onResponse, resolveApi, hubletApi } from 'hub-http/middlewares/core';
const defaultOptions = {
  method: 'GET',
  withCredentials: true,
  timeout: 7000
};
export const defaults = options => Object.assign({}, defaultOptions, options);
const getUnauthorizedHost = response => {
  return response.status === 401 ? parseUrl(response.xhr.responseURL).hostname : undefined;
};
const buildLogoutUrl = response => {
  const hostname = resolveApi(hubletApi('tools', 'hubteam'));
  const loginDescriptor = {
    hostname,
    path: '/login/'
  };
  const redirect = response.options.location.href;
  const unauthorizedHost = getUnauthorizedHost(response);
  loginDescriptor.query = params.stringify({
    redirect,
    unauthorizedHost
  });
  return buildUrl(loginDescriptor);
};
const handleLogout = response => {
  const {
    options
  } = response;
  const redirectUrl = buildLogoutUrl(response);
  return redirectTo(redirectUrl, options) ? Promise.reject(responseError(response, 'Aborting: redirection in progress')) : response;
};
export const logoutOn = predicate => options => onResponse(response => {
  return predicate(response) ? handleLogout(response) : response;
})(options);
export const logoutOnUnauthorized = logoutOn(response => response.status === 401);