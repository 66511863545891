'use es6';

const IFRAMEABLE = 'HS:iframeable';
export const getLocalSetting = (name, setting) => {
  const item = `${IFRAMEABLE}:${setting}`;
  try {
    const value = localStorage.getItem(`${item}:${name}`);
    return value != null ? value : localStorage.getItem(`${item}`);
  } catch (err) {
    return undefined;
  }
};