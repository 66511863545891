const CONTACT_FILTER = {
  field: 'activityOriginType',
  values: ['CONTACT']
};

/**
 * Returns the list of filters with the IS_CONTACT filter added if
 * it doesn't exist already.
 * @param {Immutable.List} filters A list of JavaScript objects representing api filters
 * @returns {Immutable.List} An updated list of filters.
 */
export function pushIsContactFilter(filters) {
  const matchingFilters = filters.filter(curFilter => curFilter === CONTACT_FILTER);
  if (matchingFilters.isEmpty()) {
    return filters.push(CONTACT_FILTER);
  }
  return filters;
}