import keyMirror from 'react-utils/keyMirror';
export const BundledActivityLimit = 10;
export const RequestStatus = keyMirror({
  UNINITIALIZED: null,
  PENDING: null,
  SUCCEEDED: null,
  FAILED: null
});
export const MessageTypes = {
  ERROR: 'error',
  WARN: 'warn',
  INFO: 'info',
  SUCCESS: 'success'
};
export const DELETE_ACTIVITY_BUNDLE_CONFIRMATION_MODAL = 'DELETE_ACTIVITY_BUNDLE_CONFIRMATION_MODAL';
export const OVERLAY_NONE = 'OVERLAY_NONE';
export const CREATE_CONTACT_MODAL = 'CREATE_CONTACT_MODAL';