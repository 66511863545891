import { createSelector } from 'reselect';
import I18n from 'I18n';
import { TODAY, YESTERDAY, WEEK, MONTH, OLDER, SAMPLE } from 'activity-feed-ui/constants/ActivityGroupNames';
import FilterTypes from 'activity-feed-ui/constants/FilterTypes';
import { ActivityTypeFilterValues } from 'activity-feed-ui/constants/Filters';
import getOnboardingActivities from 'activity-feed-ui/lib/onboarding/getOnboardingActivities';
import { getActivityBundles, getSearchQueryData } from './generalSelectors';
import { getDismissSampleActivity } from './userAttributesSelectors';
import { shouldShowOnboarding } from './onboardingSelectors';
export const groupActivities = createSelector(getActivityBundles, getSearchQueryData, getDismissSampleActivity, shouldShowOnboarding, (activityBundles, searchQueryData, dismissSampleActivity, showOnboarding) => {
  const {
    filters,
    searchQuery,
    hasMore
  } = searchQueryData;
  const activityTypeFilter = filters.get(FilterTypes.ACTIVITY_TYPE);
  const showSampleActivity = activityTypeFilter.value === ActivityTypeFilterValues.ALL && searchQuery === '' && !hasMore && !dismissSampleActivity;
  const today = I18n.moment();
  const yesterday = I18n.moment().subtract(1, 'days');
  const activityBundlesGroup = activityBundles.groupBy(activityBundle => {
    const notificationDate = I18n.moment(activityBundle.latestActivityForOrigin.createdAt || 0);
    if (today.isSame(notificationDate, 'day')) {
      return TODAY;
    } else if (yesterday.isSame(notificationDate, 'day')) {
      return YESTERDAY;
    } else if (today.isSame(notificationDate, 'week')) {
      return WEEK;
    } else if (today.isSame(notificationDate, 'month')) {
      return MONTH;
    }
    return OLDER;
  });
  if (showOnboarding || showSampleActivity) {
    return activityBundlesGroup.set(SAMPLE, getOnboardingActivities());
  }
  return activityBundlesGroup;
});
export const isSearchingAndNotLoadingMore = createSelector(getActivityBundles, getSearchQueryData, (activityBundles, searchQueryData) => {
  return searchQueryData.isSearching && activityBundles.isEmpty();
});
export const shouldHideStream = isSearchingAndNotLoadingMore;
export const createdAfterFilterSelector = state => state.activities.createdAfterFilter;
export const isSearchingSelector = state => state.activities.searchQueryData.isSearching;
export const searchQuerySelector = state => state.activities.searchQueryData.searchQuery;