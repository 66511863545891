'use es6';

import { Record, Set as ImmutableSet } from 'immutable';
import { API_KNOWN_KEY_MAPPINGS, API_KNOWN_VALUE_MAPPINGS, API_IDENTIFIER_MAPPINGS, OBJECT_TYPES_THAT_REQUIRE_PORTAL_ID } from '../Constants';
export default class LookupRecord extends Record({
  type: undefined,
  primaryIdentifier: undefined,
  secondaryIdentifier: undefined,
  fileManagerKey: undefined,
  dimensions: undefined
}, 'LookupRecord') {
  isKnown() {
    return Object.keys(API_KNOWN_KEY_MAPPINGS).indexOf(this.type) >= 0;
  }
  getKnownName() {
    if (!this.isKnown()) {
      return undefined;
    }
    return API_KNOWN_KEY_MAPPINGS[this.type];
  }
  getKnownObject() {
    if (!this.isKnown()) {
      return undefined;
    }
    return {
      [API_KNOWN_VALUE_MAPPINGS[this.type].primaryIdentifier]: this.primaryIdentifier,
      [API_KNOWN_VALUE_MAPPINGS[this.type].secondaryIdentifier]: this.secondaryIdentifier,
      fileManagerKey: this.fileManagerKey
    };
  }
  getIdentifierName() {
    return API_IDENTIFIER_MAPPINGS[this.type];
  }
  getIdentifier() {
    return this.primaryIdentifier;
  }
  isSameAs(lookup) {
    if (this.type === lookup.type) {
      return this.primaryIdentifier === lookup.primaryIdentifier;
    }
    const haveVid = ImmutableSet.of('contact', 'vid').has(this.type) && ImmutableSet.of('contact', 'vid').has(lookup.type);
    const haveCompanyId = ImmutableSet.of('company', 'companyId').has(this.type) && ImmutableSet.of('company', 'companyId').has(lookup.type);
    if (haveVid || haveCompanyId) {
      return this.primaryIdentifier === lookup.primaryIdentifier;
    }
    if (this.type === 'contact' && lookup.type === 'email' || this.type === 'company' && lookup.type === 'domain') {
      return this.secondaryIdentifier === lookup.primaryIdentifier;
    }
    if (this.type === 'email' && lookup.type === 'contact' || this.type === 'domain' && lookup.type === 'company') {
      return this.primaryIdentifier === lookup.secondaryIdentifier;
    }
    return false;
  }
  requiresPortalId() {
    return OBJECT_TYPES_THAT_REQUIRE_PORTAL_ID.indexOf(this.type) > -1;
  }
}