module.exports = {
  "pageLoad": {
    "name": "Pageview",
    "class": "view",
    "properties": {
      "screen": "string",
      "subscreen": {
        "type": "string"
      },
      "type": [
        "settings",
        "embedded-settings",
        "activity-feed",
        "sales-feed"
      ]
    },
    "namespace": "activity-feed-ui"
  },
  "activityEvent": {
    "name": "Activity Event",
    "class": "interaction",
    "properties": {
      "action": [
        "new-activity-rendered",
        "dismiss-activity",
        "clicked-contact-record",
        "action-button-click",
        "background-refresh-button-rendered",
        "background-refresh-button-click",
        "status-filter-click",
        "sequence-filter-selected",
        "activity-type-filter-selected",
        "click-on-meeting-link",
        "start-task-queue",
        "activity-mute-icon-click",
        "delete-activity-bundle-icon-click",
        "open-show-all-activities",
        "activity-type-filter-selected",
        "sequence-filter-selected",
        "load-more-activities-button-click"
      ],
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "activityType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "activity-feed-ui"
  },
  "searchedFeed": {
    "name": "Searched Feed",
    "class": "interaction",
    "properties": {
      "activityTypeFilter": {
        "type": "string"
      }
    },
    "namespace": "activity-feed-ui"
  },
  "onboardingView": {
    "name": "Onboarding View",
    "class": "view",
    "properties": {
      "type": [
        "connect-inbox",
        "get-first-activity-outlook",
        "get-first-activity-gmail",
        "install-chrome",
        "install-extension",
        "intro-step",
        "install-chrome-extension",
        "send-tracked-email-in-gmail",
        "install-outlook-addin",
        "send-tracked-email-in-outlook",
        "send-tracked-email-open-outlook",
        "connect-your-inbox",
        "send-tracked-email-from-crm",
        "final-step",
        "install-chrome-extension-pro-tip"
      ]
    },
    "namespace": "activity-feed-ui"
  },
  "onboardingEvent": {
    "name": "Onboarding Event",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-connect-inbox",
        "clicked-download-extension",
        "clicked-switch-browser",
        "clicked-open-gmail",
        "click-download-o365-add-on",
        "copy-cool-robot-email",
        "clicked-gmail-email-provider",
        "clicked-outlook-email-provider",
        "clicked-no-email-provider",
        "clicked-send-first-track-email-from-gmail",
        "clicked-has-completed-outlook-install",
        "clicked-install-outlook-extension",
        "clicked-send-first-track-email-from-outlook",
        "clicked-send-first-track-email-from-crm",
        "clicked-previous-step-indicator"
      ],
      "onboardingScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "activity-feed-ui"
  },
  "pageview": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "client_type": {
        "type": "string",
        "isOptional": true
      },
      "from_signals_extension": {
        "type": "string",
        "isOptional": true
      },
      "extension_version": {
        "type": "string",
        "isOptional": true
      },
      "gmail_view": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "clients"
  },
  "insert-template": {
    "name": "Insert Template",
    "class": "usage",
    "properties": {
      "windows_installation_type": {
        "type": "string",
        "isOptional": true
      },
      "windows_version": {
        "type": "string",
        "isOptional": true
      },
      "outlook_version": {
        "type": "string",
        "isOptional": true
      },
      "template_source": {
        "type": "string",
        "isOptional": true
      },
      "app_version": {
        "type": "string",
        "isOptional": true
      },
      "clientType": {
        "type": "string"
      },
      "id": {
        "type": "string"
      },
      "client_type": {
        "type": "string",
        "isOptional": true
      },
      "from_signals_extension": {
        "type": "string",
        "isOptional": true
      },
      "extension_version": {
        "type": "string",
        "isOptional": true
      },
      "gmail_view": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "clients"
  },
  "insert-document": {
    "name": "Insert Document",
    "class": "usage",
    "properties": {
      "windows_installation_type": {
        "type": "string",
        "isOptional": true
      },
      "windows_version": {
        "type": "string",
        "isOptional": true
      },
      "outlook_version": {
        "type": "string",
        "isOptional": true
      },
      "app_version": {
        "type": "string",
        "isOptional": true
      },
      "clientType": {
        "type": "string"
      },
      "client_type": {
        "type": "string",
        "isOptional": true
      },
      "from_signals_extension": {
        "type": "string",
        "isOptional": true
      },
      "extension_version": {
        "type": "string",
        "isOptional": true
      },
      "gmail_view": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "clients"
  },
  "insert-meeting": {
    "name": "Insert Meeting",
    "class": "usage",
    "properties": {
      "windows_installation_type": {
        "type": "string",
        "isOptional": true
      },
      "windows_version": {
        "type": "string",
        "isOptional": true
      },
      "outlook_version": {
        "type": "string",
        "isOptional": true
      },
      "app_version": {
        "type": "string",
        "isOptional": true
      },
      "clientType": {
        "type": "string"
      },
      "id": {
        "type": "string"
      },
      "client_type": {
        "type": "string",
        "isOptional": true
      },
      "from_signals_extension": {
        "type": "string",
        "isOptional": true
      },
      "extension_version": {
        "type": "string",
        "isOptional": true
      },
      "gmail_view": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "clients"
  },
  "insert-snippet": {
    "name": "Insert Snippet",
    "class": "usage",
    "properties": {
      "windows_installation_type": {
        "type": "string",
        "isOptional": true
      },
      "windows_version": {
        "type": "string",
        "isOptional": true
      },
      "outlook_version": {
        "type": "string",
        "isOptional": true
      },
      "clientType": {
        "type": "string"
      },
      "id": {
        "type": "string"
      },
      "client_type": {
        "type": "string",
        "isOptional": true
      },
      "from_signals_extension": {
        "type": "string",
        "isOptional": true
      },
      "extension_version": {
        "type": "string",
        "isOptional": true
      },
      "gmail_view": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "clients"
  },
  "adjust-associations": {
    "name": "Adjust Associations",
    "class": "usage",
    "properties": {
      "openEvent": {
        "type": "boolean",
        "isOptional": true
      },
      "clientType": {
        "type": "string"
      },
      "associatedContactsChanged": {
        "type": "boolean",
        "isOptional": true
      },
      "associatedCompaniesChanged": {
        "type": "boolean",
        "isOptional": true
      },
      "associatedDealsChanged": {
        "type": "boolean",
        "isOptional": true
      },
      "associatedTicketsChanged": {
        "type": "boolean",
        "isOptional": true
      },
      "client_type": {
        "type": "string",
        "isOptional": true
      },
      "from_signals_extension": {
        "type": "string",
        "isOptional": true
      },
      "extension_version": {
        "type": "string",
        "isOptional": true
      },
      "gmail_view": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "clients"
  },
  "edit-record": {
    "name": "Edit Record",
    "class": "usage",
    "properties": {
      "clientType": {
        "type": "string"
      },
      "type": {
        "type": "string"
      },
      "properties": {
        "type": "array"
      },
      "client_type": {
        "type": "string",
        "isOptional": true
      },
      "from_signals_extension": {
        "type": "string",
        "isOptional": true
      },
      "extension_version": {
        "type": "string",
        "isOptional": true
      },
      "gmail_view": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "clients"
  },
  "create-contact": {
    "name": "Create Contact",
    "class": "usage",
    "properties": {
      "clientType": {
        "type": "string"
      },
      "createMethod": {
        "type": [
          "manual",
          "email signature"
        ]
      },
      "client_type": {
        "type": "string",
        "isOptional": true
      },
      "from_signals_extension": {
        "type": "string",
        "isOptional": true
      },
      "extension_version": {
        "type": "string",
        "isOptional": true
      },
      "gmail_view": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "clients"
  },
  "search-and-filter": {
    "name": "Search and Filter",
    "class": "interaction",
    "properties": {
      "clientType": {
        "type": "string"
      },
      "type": {
        "type": [
          "contact",
          "company",
          "deal",
          "ticket",
          "custom"
        ]
      },
      "action": {
        "type": [
          "filter",
          "search"
        ]
      },
      "client_type": {
        "type": "string",
        "isOptional": true
      },
      "from_signals_extension": {
        "type": "string",
        "isOptional": true
      },
      "extension_version": {
        "type": "string",
        "isOptional": true
      },
      "gmail_view": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "clients"
  },
  "enter-hubspot": {
    "name": "Enter HubSpot",
    "class": "interaction",
    "properties": {
      "clientType": {
        "type": "string"
      },
      "method": {
        "type": "string"
      },
      "client_type": {
        "type": "string",
        "isOptional": true
      },
      "from_signals_extension": {
        "type": "string",
        "isOptional": true
      },
      "extension_version": {
        "type": "string",
        "isOptional": true
      },
      "gmail_view": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "clients"
  },
  "view-deal-create-form": {
    "name": "View Deal Create Form",
    "class": "usage",
    "properties": {
      "clientType": {
        "type": "string"
      },
      "client_type": {
        "type": "string",
        "isOptional": true
      },
      "from_signals_extension": {
        "type": "string",
        "isOptional": true
      },
      "extension_version": {
        "type": "string",
        "isOptional": true
      },
      "gmail_view": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "clients"
  },
  "create-deal-success": {
    "name": "Create Deal Success",
    "class": "usage",
    "properties": {
      "clientType": {
        "type": "string"
      },
      "client_type": {
        "type": "string",
        "isOptional": true
      },
      "from_signals_extension": {
        "type": "string",
        "isOptional": true
      },
      "extension_version": {
        "type": "string",
        "isOptional": true
      },
      "gmail_view": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "clients"
  },
  "onboarding-guide-interaction": {
    "name": "Onboarding Guide Interaction",
    "class": "interaction",
    "properties": {
      "clientType": {
        "type": "string"
      },
      "step": {
        "type": "string"
      },
      "stepId": {
        "type": "number"
      },
      "version": {
        "type": "string"
      },
      "tourName": {
        "type": "string",
        "isOptional": true
      },
      "launchType": {
        "type": "string",
        "isOptional": true
      },
      "client_type": {
        "type": "string",
        "isOptional": true
      },
      "from_signals_extension": {
        "type": "string",
        "isOptional": true
      },
      "extension_version": {
        "type": "string",
        "isOptional": true
      },
      "gmail_view": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "clients"
  },
  "inbox-connect-onboarding": {
    "name": "Inbox Connect Onboarding",
    "class": "usage",
    "properties": {
      "clientType": {
        "type": "string"
      },
      "step": {
        "type": "string"
      },
      "stepId": {
        "type": "number"
      },
      "version": {
        "type": "string"
      },
      "client_type": {
        "type": "string",
        "isOptional": true
      },
      "from_signals_extension": {
        "type": "string",
        "isOptional": true
      },
      "extension_version": {
        "type": "string",
        "isOptional": true
      },
      "gmail_view": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "clients"
  },
  "toggle-association-default": {
    "name": "Toggle Association Defaults",
    "class": "usage",
    "properties": {
      "clientType": {
        "type": "string"
      },
      "what_value": {
        "type": "string",
        "isOptional": true
      },
      "client_type": {
        "type": "string",
        "isOptional": true
      },
      "from_signals_extension": {
        "type": "string",
        "isOptional": true
      },
      "extension_version": {
        "type": "string",
        "isOptional": true
      },
      "gmail_view": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "clients"
  },
  "email-associations-active": {
    "name": "Email Associations",
    "class": "usage",
    "properties": {
      "clientType": {
        "type": "string"
      },
      "client_type": {
        "type": "string",
        "isOptional": true
      },
      "from_signals_extension": {
        "type": "string",
        "isOptional": true
      },
      "extension_version": {
        "type": "string",
        "isOptional": true
      },
      "gmail_view": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "clients"
  },
  "email-associations-unsupported": {
    "name": "Email Associations UnSupported",
    "class": "usage",
    "properties": {
      "platform": {
        "type": "string"
      },
      "client_type": {
        "type": "string",
        "isOptional": true
      },
      "from_signals_extension": {
        "type": "string",
        "isOptional": true
      },
      "extension_version": {
        "type": "string",
        "isOptional": true
      },
      "gmail_view": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "clients"
  },
  "append-on-send-active": {
    "name": "Append On Send Active",
    "class": "usage",
    "properties": {
      "platform": {
        "type": "string"
      },
      "client_type": {
        "type": "string",
        "isOptional": true
      },
      "from_signals_extension": {
        "type": "string",
        "isOptional": true
      },
      "extension_version": {
        "type": "string",
        "isOptional": true
      },
      "gmail_view": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "clients"
  },
  "toolbar-buttons-interactions": {
    "name": "Toolbar Buttons User Actions",
    "class": "usage",
    "properties": {
      "clientType": {
        "type": "string"
      },
      "buttonType": {
        "type": "string"
      },
      "action": {
        "type": "string"
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "hasRecipient": {
        "type": "boolean",
        "isOptional": true
      },
      "sequence": {
        "type": "string",
        "isOptional": true
      },
      "client_type": {
        "type": "string",
        "isOptional": true
      },
      "from_signals_extension": {
        "type": "string",
        "isOptional": true
      },
      "extension_version": {
        "type": "string",
        "isOptional": true
      },
      "gmail_view": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "clients"
  }
};