import { Map as ImmutableMap } from 'immutable';
import ActivityTypeFilterRecord from 'activity-feed-ui/records/filters/ActivityTypeFilterRecord';
import EnrollmentStatusFilterRecord from 'activity-feed-ui/records/filters/EnrollmentStatusFilterRecord';
import { ActivityTypeFilterValues, EnrollmentStatusFilterValues } from 'activity-feed-ui/constants/Filters';
export function createActivityTypeFilter(activityTypeValue) {
  if (activityTypeValue && activityTypeValue in ActivityTypeFilterValues) {
    return new ActivityTypeFilterRecord({
      value: activityTypeValue
    });
  }
  return new ActivityTypeFilterRecord({
    value: ActivityTypeFilterValues.ALL
  });
}
function createEnrollmentStatusFilter(enrollmentStatusValue) {
  if (enrollmentStatusValue && enrollmentStatusValue in EnrollmentStatusFilterValues) {
    return new EnrollmentStatusFilterRecord({
      value: enrollmentStatusValue
    });
  }
  return new EnrollmentStatusFilterRecord({
    value: EnrollmentStatusFilterValues.ALL
  });
}
export function createFilters(queryParams) {
  const {
    activityType,
    enrolledStatus
  } = queryParams;
  return ImmutableMap({
    ACTIVITY_TYPE: createActivityTypeFilter(activityType),
    ENROLLMENT_STATUS: createEnrollmentStatusFilter(enrolledStatus)
  });
}