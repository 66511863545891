'use es6';

const fetched = {};
export function isFetched(src) {
  return fetched[src];
}
export function fetchImage(src) {
  if (isFetched(src)) {
    return Promise.resolve();
  }
  return new Promise((resolve, reject) => {
    const img = new Image();
    const onload = () => {
      fetched[src] = true;
      resolve();
    };
    img.onerror = reject;
    img.onload = onload;
    img.src = src;
  });
}