import { ACCOUNTS_FETCH, ACCOUNTS_FETCH_FAILURE, ACCOUNTS_FETCH_SUCCESS } from 'activity-feed-ui/redux/actionTypes/ActionTypes';
const initialState = {
  accounts: null,
  accountsError: false
};
export default ((state = initialState, action) => {
  switch (action.type) {
    case ACCOUNTS_FETCH:
      return Object.assign({}, state, {
        accounts: null,
        accountsError: false
      });
    case ACCOUNTS_FETCH_SUCCESS:
      {
        return Object.assign({}, state, {
          accounts: action.payload,
          accountsError: false
        });
      }
    case ACCOUNTS_FETCH_FAILURE:
      return Object.assign({}, state, {
        accountsError: true
      });
    default:
      return state;
  }
});