/**
 * An Object with type annotations containing all the network and type
 * information needed to model an http request as an RPC. See
 * `createHttpRpcClientV1` for more details.
 */

/**
 * The path parameters, query parameters, and request body for an RPC request
 * are passed as object properties to the RPC client function. This object is
 * relatively simple, but its type is highly overloaded depending on which
 * pieces of data are required for a given RPC. The complexity of this type is
 * mostly to do with determining whether each of the three properties on this
 * type should be required given the RPC being executed. Most of the time
 * you can think of this type as:
 *
 * ```
 * interface RpcInputs<RequestBody, PathParameters, QueryParameters> {
 *   data?: RequestBody,
 *   pathParameters?: PathParameters,
 *   queryParameters?: QueryParameters
 * }
 * ```
 */

const _unhandledEnumCase = Symbol('unhandled-enum-case');
/**
 * `unhandledEnumCase` is an opaque type brand representing values which could
 * later be added to an enum. You can access the underlying `string` type of
 * unhandled enum cases using the `readUnhandledEnumCase` utility function. The
 * presence of this type is intended to force consumers to handle enum expansion
 * gracefully. This guardrail is massively beneficial to backend engineers
 * because it means enum expansion doesn't count as a breaking change from a
 * schema evolution perspective.
 */

export function readUnhandledEnumCase(unhandledEnumCase) {
  if (typeof unhandledEnumCase === 'string') {
    return unhandledEnumCase;
  }
  // this should never happen. The type unhandledEnumCase should always at
  // runtime be a string. We don't support non-string enums.
  throw new Error('unhandledEnumCase had unexpected non-string type');
}