export const ACCOUNTS_FETCH = 'ACCOUNTS_FETCH';
export const ACCOUNTS_FETCH_FAILURE = 'ACCOUNTS_FETCH_FAILURE';
export const ACCOUNTS_FETCH_SUCCESS = 'ACCOUNTS_FETCH_SUCCESS';
export const ACTIVITIES_FOR_ORIGIN = 'ACTIVITIES_FOR_ORIGIN';
export const ACTIVITIES_FOR_ORIGIN_FAILURE = 'ACTIVITIES_FOR_ORIGIN_FAILURE';
export const ACTIVITIES_FOR_ORIGIN_SUCCESS = 'ACTIVITIES_FOR_ORIGIN_SUCCESS';
export const ACTIVITY_BUNDLE_DELETED_SUCCESS = 'ACTIVITY_BUNDLE_DELETED_SUCCESS';
export const ACTIVITY_FEED_SEARCH = 'ACTIVITY_FEED_SEARCH';
export const ACTIVITY_FEED_SEARCH_FAILURE = 'ACTIVITY_FEED_SEARCH_FAILURE';
export const ACTIVITY_FEED_SEARCH_SUCCESS = 'ACTIVITY_FEED_SEARCH_SUCCESS';
export const APP_INIT = 'APP_INIT';
export const CONNECTED_INBOX_FETCH_SUCCESS = 'CONNECTED_INBOX_FETCH_SUCCESS';
export const CONTACT_CREATE = 'CONTACT_CREATE';
export const CONTACT_CREATE_FAILURE = 'CONTACT_CREATE_FAILURE';
export const CONTACT_CREATE_SUCCESS = 'CONTACT_CREATE_SUCCESS';
export const ENGAGEMENT_UPDATE = 'ENGAGEMENT_UPDATE';
export const ENGAGEMENT_UPDATE_FAILURE = 'ENGAGEMENT_UPDATE_FAILURE';
export const ENGAGEMENT_UPDATE_SUCCESS = 'ENGAGEMENT_UPDATE_SUCCESS';
export const MEETINGS_FETCH = 'MEETINGS_FETCH';
export const MEETINGS_FETCH_FAILURE = 'MEETINGS_FETCH_FAILURE';
export const MEETINGS_FETCH_SUCCESS = 'MEETINGS_FETCH_SUCCESS';
export const MONTHLY_COUNT_FETCH_FAILURE = 'MONTHLY_COUNT_FETCH_FAILURE';
export const MONTHLY_COUNT_FETCH_SUCCESS = 'MONTHLY_COUNT_FETCH_SUCCESS';
export const OVERLAY_OPEN_MODAL = 'OVERLAY_OPEN_MODAL';
export const OVERLAY_CLOSE_MODAL = 'OVERLAY_CLOSE_MODAL';
export const SET_CREATED_AFTER_FILTER = 'SET_CREATED_AFTER_FILTER';
export const SET_DISMISS_ONBOARDING = 'SET_DISMISS_ONBOARDING';
export const SET_DISMISS_SAMPLE_ACTIVITY = 'SET_DISMISS_SAMPLE_ACTIVITY';
export const SET_HAS_RECEIVED_MORE_ACTIVITIES = 'SET_HAS_RECEIVED_MORE_ACTIVITIES';
export const SET_SEARCH_QUERY_DATA = 'SET_SEARCH_QUERY_DATA';
export const TASKS_FETCH = 'TASKS_FETCH';
export const TASKS_FETCH_FAILURE = 'TASKS_FETCH_FAILURE';
export const TASKS_FETCH_SUCCESS = 'TASKS_FETCH_SUCCESS';
export const TOGGLE_ACTIVITY_EXPANDED = 'TOGGLE_ACTIVITY_EXPANDED';
export const UPDATE_ACTIVITY_BUNDLE = 'UPDATE_ACTIVITY_BUNDLE';