import I18n from 'I18n';
// @ts-expect-error I18n component not typed
import formatMediumDate from 'I18n/utils/formatMediumDate';
export default function getDisplayDate(timestamp) {
  const today = I18n.moment.userTz();
  const yesterday = I18n.moment.userTz().subtract(1, 'days');
  const notificationDate = I18n.moment.userTz(timestamp);
  const time = notificationDate.format('LT');
  if (today.isSame(notificationDate, 'days')) {
    return time;
  } else if (yesterday.isSame(notificationDate, 'days')) {
    return I18n.text('displayDate.yesterdayDate', {
      time
    });
  } else if (today.isSame(notificationDate, 'years')) {
    return formatMediumDate(notificationDate);
  }
  return notificationDate.format('LL');
}