import { List, Map as ImmutableMap } from 'immutable';
import { MEETINGS_FETCH, MEETINGS_FETCH_SUCCESS, MEETINGS_FETCH_FAILURE } from 'activity-feed-ui/redux/actionTypes/ActionTypes';
const initialState = {
  meetings: List(),
  contacts: ImmutableMap(),
  loading: true,
  hasError: false
};
export default ((state = initialState, action) => {
  switch (action.type) {
    case MEETINGS_FETCH:
      return initialState;
    case MEETINGS_FETCH_SUCCESS:
      {
        const {
          meetings,
          contacts
        } = action.payload;
        return Object.assign({}, state, {
          meetings,
          contacts,
          loading: false,
          hasError: false
        });
      }
    case MEETINGS_FETCH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        hasError: true
      });
    default:
      return state;
  }
});