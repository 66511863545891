const ActivityTypes = {
  EMAIL_OPEN: 'EMAIL_OPEN',
  EMAIL_CLICK: 'EMAIL_CLICK',
  EMAIL_TRACKER_CREATE: 'EMAIL_TRACKER_CREATE',
  HUBSPOT_REVISIT: 'HUBSPOT_REVISIT',
  COMPANYPROSPECTS_REVISIT: 'COMPANYPROSPECTS_REVISIT',
  PRESENTATION_REVISIT: 'PRESENTATION_REVISIT',
  MEETING_BOOKED: 'MEETING_BOOKED',
  FORM_SUBMITTED: 'FORM_SUBMITTED'
};
export default ActivityTypes;