import PortalIdParser from 'PortalIdParser';
import apiClient from 'hub-http/clients/apiClient';
import { hubapi } from 'hub-http/middlewares/hubapi';
import promiseClient from 'hub-http/adapters/promiseClient';
import { createHubSpotterStack } from 'hubspotter-http/stacks/hubSpotter';
import { isInternal, getUserId } from 'activity-feed-ui/utils/internalUtils';
const apiHubSpotterClient = promiseClient(createHubSpotterStack(hubapi));
export const getClient = () => isInternal() ? apiHubSpotterClient : apiClient;
const createOptions = options => {
  if (isInternal()) {
    return Object.assign({}, options, {
      headers: Object.assign({}, options.headers, {
        'X-Origin-UserId': getUserId()
      }),
      query: Object.assign({}, options.query, {
        portalId: PortalIdParser.get()
      })
    });
  }
  return options;
};
export const get = (url, options) => getClient().get(url, createOptions(options));
export const deleteRequest = (url, options) => getClient().delete(url, createOptions(options));
export const put = (url, options) => getClient().put(url, createOptions(options));
export const post = (url, options) => getClient().post(url, createOptions(options));
export default getClient;