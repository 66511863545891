import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["searchQuery"],
  _excluded2 = ["searchQuery"];
import { Record, Map as ImmutableMap, List } from 'immutable';
import { setQueryParams } from 'activity-feed-ui/utils/setQueryParams';
import { getQueryParams } from 'activity-feed-ui/utils/getQueryParams';
import { createFilters } from 'activity-feed-ui/utils/FilterFactory';
const defaultSearchQueryDataValues = {
  filters: ImmutableMap(),
  hasMore: true,
  isSearching: true,
  offset: 0,
  searchError: false,
  searchQuery: ''
};
const SearchQueryDataFactory = Record(defaultSearchQueryDataValues, 'SearchQueryData');
export default class SearchQueryDataRecord extends SearchQueryDataFactory {
  getFilter(filterType) {
    return this.filters.get(filterType);
  }
  getFilterValue(filterType) {
    return this.getIn(['filters', filterType, 'value']);
  }
  setFilter(filterRecord, shouldUpdateQueryParams = true) {
    const updatedRecord = this.setIn(['filters', filterRecord.type], filterRecord).merge({
      hasMore: false,
      offset: 0,
      isSearching: true,
      searchError: false
    });
    if (shouldUpdateQueryParams) {
      return updatedRecord.updateQueryParams();
    }
    return updatedRecord;
  }
  hasDefaultFilters() {
    return this.filters.reduce((hasBeenDefaultFilters, filterRecord) => {
      return hasBeenDefaultFilters && filterRecord.isDefaultValue();
    }, true);
  }
  hasDefaultSearchQuery() {
    return !(this.searchQuery && this.searchQuery !== '');
  }
  hasFilterOrQuery() {
    return !this.hasDefaultFilters() || !this.hasDefaultSearchQuery();
  }
  setSearchQuery(searchQuery) {
    return this.merge({
      searchQuery,
      hasMore: false,
      offset: 0,
      isSearching: true,
      searchError: false
    }).updateQueryParams();
  }
  updateQueryParams() {
    const queryParams = this.filters.reduce((params, filterRecord) => filterRecord.toQueryParams(params), {
      searchQuery: this.searchQuery
    });
    setQueryParams(queryParams);
    return this;
  }
  getSearchQueryFilters() {
    const filtersMap = ImmutableMap({
      activityFeedFilters: List(),
      contactSearchFilters: List()
    });
    return this.filters.reduce((searchQueryFilters, filterRecord) => {
      return filterRecord.toQuery(searchQueryFilters);
    }, filtersMap).toJS();
  }
  toQuery({
    clearActivities = false
  } = {}) {
    const {
      searchQuery,
      offset
    } = this;
    const {
      activityFeedFilters,
      contactSearchFilters
    } = this.getSearchQueryFilters();
    return {
      offset: clearActivities ? 0 : offset,
      filters: activityFeedFilters,
      contactSearchFilters,
      query: searchQuery,
      limit: 10
    };
  }
}
SearchQueryDataRecord.createFromQueryParams = () => {
  const _getQueryParams = getQueryParams(),
    {
      searchQuery
    } = _getQueryParams,
    queryParams = _objectWithoutPropertiesLoose(_getQueryParams, _excluded);
  return new SearchQueryDataRecord({
    searchQuery: searchQuery || '',
    filters: createFilters(queryParams)
  });
};
SearchQueryDataRecord.createFromEarlyRequesterData = ({
  hasMore,
  offset
}) => {
  const _getQueryParams2 = getQueryParams(),
    {
      searchQuery
    } = _getQueryParams2,
    queryParams = _objectWithoutPropertiesLoose(_getQueryParams2, _excluded2);
  return new SearchQueryDataRecord({
    searchQuery: searchQuery || '',
    hasMore,
    offset,
    isSearching: false,
    filters: createFilters(queryParams)
  });
};