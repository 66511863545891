import identity from 'transmute/identity';
// @ts-expect-error untyped
import createAction from 'flux-actions/createAction';
// @ts-expect-error Untyped
import ConnectedAccountsApi from 'activity-feed-ui/api/ConnectedAccountsApi';
import { CONNECTED_INBOX_FETCH_SUCCESS } from 'activity-feed-ui/redux/actionTypes/ActionTypes';
const fetchConnectedInboxSuccess = createAction(CONNECTED_INBOX_FETCH_SUCCESS, identity);
export const fetchConnectedInbox = () => dispatch => {
  return ConnectedAccountsApi.fetchInbox().then(connectedAccount => {
    if (connectedAccount && !connectedAccount.isEmpty()) {
      dispatch(fetchConnectedInboxSuccess(connectedAccount));
    }
  });
};