/* hs-eslint ignored failing-rules */

import PortalIdParser from 'PortalIdParser';
import isNumber from 'transmute/isNumber';
import userInfo from 'hub-http/userInfo';
import { getId } from 'customer-data-objects/protocol/Identifiable';
import OwnerRecord from 'customer-data-objects/owners/OwnerRecord';
import { isInternal, getUserId } from 'activity-feed-ui/utils/internalUtils';
import { get } from './HubSpotClient';
export const BASE_PATH = 'owners/v2/owners';
function fetchCurrentOwner() {
  return get(`${BASE_PATH}/current/remotes`).then(OwnerRecord.fromJS).catch(error => {
    // a 404 means this is a JITA user
    if (error.status === 404) {
      return null;
    }
    throw error;
  });
}
export default {
  fetchCurrentOwnerIdFromCache() {
    return userInfo().then(info => {
      const userId = isInternal() ? getUserId() : info.user.user_id;
      const key = `OID:${userId}:${PortalIdParser.get()}`;
      let cachedId = null;
      try {
        cachedId = localStorage.getItem(key);
      } catch (e) {
        // ignore
      }
      if (isNumber(cachedId) && cachedId > 0) {
        return Promise.resolve(cachedId);
      }
      return fetchCurrentOwner().then(owner => {
        if (!owner) {
          return -1;
        }
        const ownerId = getId(owner);
        try {
          localStorage.setItem(key, ownerId);
        } catch (e) {
          // ignore
        }
        return ownerId;
      });
    });
  }
};