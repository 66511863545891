import { createSelector } from 'reselect';
// @ts-expect-error ExtensionInstallHelpers are not typed
import SidekickExtensionInstallHelpers from 'ExtensionInstallHelpers.SidekickExtensionInstallHelpers';
import localSettings from 'activity-feed-ui/lib/localSettings';
import { HAS_DISMISS_SAMPLE_ACTIVITY } from 'activity-feed-ui/constants/LocalStorageKeys';
import { getActivityBundles, getSearchQueryData } from './generalSelectors';
import { selectUseCondensedView } from './uiSelectors';
import { getDismissOnboarding } from './userAttributesSelectors';
export const shouldShowOnboarding = createSelector(getActivityBundles, getSearchQueryData, getDismissOnboarding, (activityBundles, searchQueryData, dismissOnboarding) => {
  return !searchQueryData.searchError && searchQueryData.searchQuery === '' && searchQueryData.hasDefaultFilters() && !searchQueryData.isSearching && activityBundles.isEmpty() && !dismissOnboarding;
});
export const shouldHideChromeExtensionProTip = createSelector(selectUseCondensedView, shouldShowOnboarding, (useCondensedView, showOnboarding) => {
  const hasDismissed = localSettings.get(HAS_DISMISS_SAMPLE_ACTIVITY);
  return useCondensedView || !SidekickExtensionInstallHelpers.isUsingChrome || SidekickExtensionInstallHelpers.hasExtension() || hasDismissed || showOnboarding;
});