export default {
  create() {
    let _data = null;
    return {
      set(data) {
        return _data = data;
      },
      get() {
        return _data;
      }
    };
  }
};