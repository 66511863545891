import { APP_INIT } from 'activity-feed-ui/redux/actionTypes/ActionTypes';
import { CUSTOMER_SUCCESS_WORKSPACE, PROSPECTING_WORKSPACE, SALES_ACTIVITIES } from '../../constants/StreamSources';
const initialState = {
  useCondensedView: false
};
export default ((state = initialState, action) => {
  switch (action.type) {
    case APP_INIT:
      {
        const {
          isEmbedded,
          streamSource
        } = action.payload;
        return Object.assign({}, state, {
          // Prospecting workspace and sales activities are special use cases for embedded version
          // We want embedded AF in those apps to look like and have same functionality as full activity feed
          useCondensedView: isEmbedded && ![PROSPECTING_WORKSPACE, SALES_ACTIVITIES, CUSTOMER_SUCCESS_WORKSPACE].includes(streamSource)
        });
      }
    default:
      return state;
  }
});