import { Record } from 'immutable';
import { pushIsContactFilter } from 'activity-feed-ui/utils/pushFilterUtils';
import FilterTypes from 'activity-feed-ui/constants/FilterTypes';
import { EnrollmentStatusFilterValues } from 'activity-feed-ui/constants/Filters';
const defaultEnrollmentStateFilterValues = {
  type: FilterTypes.ENROLLMENT_STATUS,
  value: EnrollmentStatusFilterValues.ALL,
  options: Object.values(EnrollmentStatusFilterValues)
};
export const makeEnrollmentStatusFilter = Record(defaultEnrollmentStateFilterValues, 'EnrollmentStatusFilterRecord');
export default class EnrollmentStatusFilterRecord extends makeEnrollmentStatusFilter {
  isDefaultValue() {
    return this.value === EnrollmentStatusFilterValues.ALL;
  }
  setValue(newValue) {
    return this.set('value', newValue);
  }
  toQuery(filters) {
    if (this.value === EnrollmentStatusFilterValues.ALL) {
      return filters;
    }
    return filters.update('activityFeedFilters', activityFeedFilters => {
      if (this.value === EnrollmentStatusFilterValues.IN_SEQUENCE) {
        return pushIsContactFilter(activityFeedFilters);
      }
      return activityFeedFilters;
    }).update('contactSearchFilters', contactSearchFilters => contactSearchFilters.push({
      property: 'hs_sequences_is_enrolled',
      operator: this.value === EnrollmentStatusFilterValues.IN_SEQUENCE ? 'EQ' : 'NEQ',
      value: true
    }));
  }
  toQueryParams(queryParams) {
    return Object.assign({}, queryParams, {
      enrolledStatus: this.value === EnrollmentStatusFilterValues.ALL ? undefined : this.value
    });
  }
}