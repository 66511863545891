import once from 'transmute/once';
const queryParamsRegex = name => new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
const getParameterByName = name => {
  const url = window.location.href;
  const results = queryParamsRegex(name).exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
export const getUserId = once(() => getParameterByName('userId'));
export const isInternal = once(() => window.location.host.includes('hubteam'));