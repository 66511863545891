'use es6';

import quickFetch from 'quick-fetch';
import formatActivityResults from 'activity-feed-ui/lib/formatActivityResults';
export default (() => {
  const earlyRequestState = quickFetch.getRequestStateByName('activity-search');
  if (!earlyRequestState || earlyRequestState.errored || !earlyRequestState.finished) {
    return undefined;
  }
  return formatActivityResults(earlyRequestState.data);
});