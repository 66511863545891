import ActivityTypes from 'activity-feed-ui/constants/ActivityTypes';
export const ActivityTypeFilterValues = {
  ALL: 'ALL',
  EMAIL_OPEN: ActivityTypes.EMAIL_OPEN,
  EMAIL_CLICK: ActivityTypes.EMAIL_CLICK,
  EMAIL_TRACKER_CREATE: ActivityTypes.EMAIL_TRACKER_CREATE,
  HUBSPOT_REVISIT: ActivityTypes.HUBSPOT_REVISIT,
  COMPANYPROSPECTS_REVISIT: ActivityTypes.COMPANYPROSPECTS_REVISIT,
  PRESENTATION_REVISIT: ActivityTypes.PRESENTATION_REVISIT,
  MEETING_BOOKED: ActivityTypes.MEETING_BOOKED,
  FORM_SUBMITTED: ActivityTypes.FORM_SUBMITTED
};
export const EnrollmentStatusFilterValues = {
  ALL: 'ALL',
  IN_SEQUENCE: 'IN_SEQUENCE',
  NOT_IN_SEQUENCE: 'NOT_IN_SEQUENCE'
};
export const CreatedAfterFilterValues = {
  ALL: 'ALL',
  ONE_MONTH_AGO: 'ONE_MONTH_AGO'
};
export const DEFAULT_CREATED_AFTER_FILTER_VALUE = CreatedAfterFilterValues.ONE_MONTH_AGO;