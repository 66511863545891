import Raven from 'raven-js';
import I18n from 'I18n';
import debounce from 'transmute/debounce';
// @ts-expect-error untyped
import createAction from 'flux-actions/createAction';
import identity from 'transmute/identity';

// @ts-expect-error Untyped
import fetchWithEarlyRequestHook from 'activity-feed-ui/earlyRequester/fetchWithEarlyRequestHook';
import ActivityFeedApi from 'activity-feed-ui/api/ActivityFeedApi';
import { ACTIVITIES_FOR_ORIGIN, ACTIVITIES_FOR_ORIGIN_SUCCESS, ACTIVITIES_FOR_ORIGIN_FAILURE, ACTIVITY_FEED_SEARCH, ACTIVITY_FEED_SEARCH_SUCCESS, ACTIVITY_FEED_SEARCH_FAILURE, MONTHLY_COUNT_FETCH_SUCCESS, MONTHLY_COUNT_FETCH_FAILURE, SET_CREATED_AFTER_FILTER, SET_HAS_RECEIVED_MORE_ACTIVITIES, SET_SEARCH_QUERY_DATA, UPDATE_ACTIVITY_BUNDLE } from 'activity-feed-ui/redux/actionTypes/ActionTypes';
import { rethrowError } from 'UIComponents/core/PromiseHandlers';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import formatActivityFeedApiFilters from 'activity-feed-ui/utils/formatActivityFeedApiFilters';
import { CreatedAfterFilterValues, DEFAULT_CREATED_AFTER_FILTER_VALUE } from 'activity-feed-ui/constants/Filters';
import { ACTIVITY_BUNDLE_DELETED_SUCCESS } from '../actionTypes/ActionTypes';
import { fetchAddOns } from '../../lib/fetchAddOns';
import { loadAddOns, updateAddOns } from '../reducers/AddOns';
const searchActivitiesStarted = createAction(ACTIVITY_FEED_SEARCH, identity);
const searchActivitiesSuccess = createAction(ACTIVITY_FEED_SEARCH_SUCCESS, identity);
const searchActivitiesFailure = createAction(ACTIVITY_FEED_SEARCH_FAILURE, identity);
const fetchActivitiesForBundleStarted = createAction(ACTIVITIES_FOR_ORIGIN, identity);
const fetchActivitiesForBundleSuccess = createAction(ACTIVITIES_FOR_ORIGIN_SUCCESS, identity);
const fetchActivitiesForBundleFailure = createAction(ACTIVITIES_FOR_ORIGIN_FAILURE, identity);
const fetchMonthlyCountSuccess = createAction(MONTHLY_COUNT_FETCH_SUCCESS, identity);
const fetchMonthlyCountFailure = createAction(MONTHLY_COUNT_FETCH_FAILURE, identity);
const setHasReceivedMoreActivities = createAction(SET_HAS_RECEIVED_MORE_ACTIVITIES, identity);
const updateActivityBundle = createAction(UPDATE_ACTIVITY_BUNDLE, identity);
const deleteActivityBundleAction = createAction(ACTIVITY_BUNDLE_DELETED_SUCCESS, identity);
export const setCreatedAfterFilterAction = createAction(SET_CREATED_AFTER_FILTER, identity);
const setSearchQueryAction = createAction(SET_SEARCH_QUERY_DATA, identity);
let backgroundActivityCheck;
const TWO_MINUTES = 2 * 60 * 1000;
const startActivityBackgroundCheck = (dispatch, getState) => {
  const {
    hasReceivedMoreActivities
  } = getState().backgroundActivitiesCheck;
  if (hasReceivedMoreActivities) {
    return;
  }
  backgroundActivityCheck = setInterval(() => {
    const {
      searchQueryData,
      createdAfterFilter
    } = getState().activities;
    // apply time limit filter only if search query unspecified
    const createdAfterFilterValue = searchQueryData.searchQuery === '' ? createdAfterFilter || DEFAULT_CREATED_AFTER_FILTER_VALUE : CreatedAfterFilterValues.ALL;
    ActivityFeedApi.searchBundled(Object.assign({}, searchQueryData.toQuery(), {
      limit: 1,
      offset: 0
    }), createdAfterFilterValue).then(response => {
      const {
        mostRecentActivity
      } = getState().backgroundActivitiesCheck;
      const results = response.get('results');
      if (results.isEmpty() || !mostRecentActivity) {
        return;
      }
      const activityRecord = results.first();
      if (activityRecord.isEqual(mostRecentActivity)) {
        return;
      }
      dispatch(setHasReceivedMoreActivities());
    }).catch(err => {
      if (err && (err.status === 0 || err.status === 403)) {
        return;
      }
      throw err;
    });
  }, TWO_MINUTES);
};
export const searchActivities = ({
  clearActivities = false,
  isInitial = false
} = {}) => (dispatch, getState) => {
  const {
    searchQueryData,
    createdAfterFilter
  } = getState().activities;
  // apply time limit filter only if search query unspecified
  const createdAfterFilterValue = searchQueryData.searchQuery === '' ? createdAfterFilter || DEFAULT_CREATED_AFTER_FILTER_VALUE : CreatedAfterFilterValues.ALL;
  clearInterval(backgroundActivityCheck);
  dispatch(searchActivitiesStarted({
    clearActivities
  }));
  const fallbackFetch = () => ActivityFeedApi.searchBundled(searchQueryData.toQuery({
    clearActivities
  }), createdAfterFilterValue);
  const searchPromise = isInitial ? fetchWithEarlyRequestHook(fallbackFetch, 'activity-search') : fallbackFetch();
  searchPromise.then(response => {
    startActivityBackgroundCheck(dispatch, getState);
    dispatch(searchActivitiesSuccess(Object.assign({}, response.toObject(), {
      originalOffset: searchQueryData.offset
    })));
    fetchAddOns(response.get('results')).then(results => {
      if (results) dispatch(isInitial ? loadAddOns(results) : updateAddOns(results));
    }).catch(rethrowError);
  }).catch(err => {
    FloatingAlertStore.addAlert({
      message: I18n.text('errors.searchActivities'),
      titleText: I18n.text('errors.error'),
      type: 'danger'
    });
    dispatch(searchActivitiesFailure(err));
    if (err && (err.status === 0 || err.status === 403)) {
      return;
    }
    throw err;
  });
};
const debouncedSearchActivities = debounce(300, ({
  clearActivities,
  dispatch,
  getState
}) => searchActivities({
  clearActivities
})(dispatch, getState));
export const setSearchQuery = ({
  searchQueryData,
  debounced = false
}) => (dispatch, getState) => {
  dispatch(setSearchQueryAction(searchQueryData));
  if (debounced) {
    return debouncedSearchActivities({
      dispatch,
      getState,
      clearActivities: true
    });
  }
  return searchActivities({
    clearActivities: true
  })(dispatch, getState);
};
export const fetchActivitiesForBundle = ({
  activityBundle,
  offset = 0
}) => dispatch => {
  const activityOriginType = activityBundle.getOriginType();
  const activityOriginId = activityBundle.getOriginId();
  const filters = formatActivityFeedApiFilters({
    activityOriginType,
    activityOriginId
  });
  dispatch(fetchActivitiesForBundleStarted({
    activityBundle
  }));
  return ActivityFeedApi.search({
    filters,
    offset
  }).then(response => {
    dispatch(fetchActivitiesForBundleSuccess(Object.assign({}, response.toObject(), {
      activityBundle
    })));
  }).catch(error => {
    FloatingAlertStore.addAlert({
      message: I18n.text('errors.fetchActivitiesForOrigin'),
      titleText: I18n.text('errors.error'),
      type: 'danger'
    });
    dispatch(fetchActivitiesForBundleFailure({
      activityBundle,
      error
    }));
    rethrowError(error);
  });
};
export const fetchMonthlyCount = () => dispatch => {
  return ActivityFeedApi.fetchMonthlyCount().then(data => dispatch(fetchMonthlyCountSuccess(data)), err => {
    if (!err || err.status !== 0) {
      Raven.captureMessage(`fetchMonthlyCount error: ${err.status}`, {
        extra: err
      });
    }
    FloatingAlertStore.addAlert({
      message: I18n.text('errors.fetchMonthlyCount'),
      titleText: I18n.text('errors.warning'),
      type: 'warning'
    });
    dispatch(fetchMonthlyCountFailure(err));
  });
};

// Though activityType and origin info are specified, API will mute/unmute everything with same thread ID if the activity type
// is email-related
export const muteThreadNotifications = originUniqueKey => (dispatch, getState) => {
  const activityBundle = getState().activities.activityBundles.get(originUniqueKey);
  const primaryActivity = activityBundle.getPrimaryActivity();
  const {
    activityOriginType,
    activityOriginId,
    activityType
  } = primaryActivity;
  const threadId = primaryActivity.getThreadId();
  return ActivityFeedApi.muteThreadNotifications({
    activityType,
    activityOriginType,
    activityOriginId,
    threadId
  }).then(() => {
    const newBundle = activityBundle.updateActivityMutedStatus(threadId, true);
    dispatch(updateActivityBundle({
      newBundle
    }));
    FloatingAlertStore.addAlert({
      titleText: I18n.text('alerts.muteSuccessTitle'),
      message: I18n.text('alerts.muteSuccessMessage'),
      type: 'success'
    });
  }).catch(err => {
    if (!err || err.status !== 0) {
      Raven.captureMessage(`muteThreadNotifications error: ${err.status}`, {
        extra: err
      });
    }
    FloatingAlertStore.addAlert({
      message: I18n.text('alerts.muteFailureMessage'),
      titleText: I18n.text('errors.warning'),
      type: 'warning'
    });
  });
};
export const unmuteThreadNotifications = originUniqueKey => (dispatch, getState) => {
  const activityBundle = getState().activities.activityBundles.get(originUniqueKey);
  const primaryActivity = activityBundle.getPrimaryActivity();
  const {
    activityOriginType,
    activityOriginId,
    activityType
  } = primaryActivity;
  const threadId = primaryActivity.getThreadId();
  ActivityFeedApi.unmuteThreadNotifications({
    activityType,
    activityOriginType,
    activityOriginId,
    threadId
  }).then(() => {
    const newBundle = activityBundle.updateActivityMutedStatus(threadId, false);
    dispatch(updateActivityBundle({
      newBundle
    }));
    FloatingAlertStore.addAlert({
      titleText: I18n.text('alerts.unmuteSuccessTitle'),
      message: I18n.text('alerts.unmuteSuccessMessage'),
      type: 'success'
    });
  }).catch(err => {
    if (!err || err.status !== 0) {
      Raven.captureMessage(`unmuteThreadNotifications error: ${err.status}`, {
        extra: err
      });
    }
    FloatingAlertStore.addAlert({
      message: I18n.text('alerts.unmuteFailureMessage'),
      titleText: I18n.text('errors.warning'),
      type: 'warning'
    });
  });
};
export const deleteActivityBundle = originUniqueKey => (dispatch, getState) => {
  const activityBundle = getState().activities.activityBundles.get(originUniqueKey);
  const primaryActivity = activityBundle.getPrimaryActivity();
  const {
    activityOriginType,
    activityOriginId
  } = primaryActivity;
  ActivityFeedApi.deleteActivityBundle({
    activityOriginType,
    activityOriginId
  }).then(() => {
    dispatch(deleteActivityBundleAction({
      originUniqueKey
    }));
    FloatingAlertStore.addAlert({
      titleText: I18n.text('alerts.deleteBundleSuccessTitle'),
      message: I18n.text('alerts.deleteBundleSuccessMessage'),
      type: 'success'
    });
  }).catch(err => {
    if (!err || err.status !== 0) {
      Raven.captureMessage(`deleteActivityBundle error: ${err.status}`, {
        extra: err
      });
    }
    FloatingAlertStore.addAlert({
      message: I18n.text('alerts.deleteBundleFailureMessage'),
      titleText: I18n.text('errors.error'),
      type: 'danger'
    });
  });
};