'use es6';

import { Map as ImmutableMap, fromJS } from 'immutable';
import { SALES_EXTENSION_DEFAULT_ASSOCIATIONS_FOR_NEW_OBJECTS } from 'sales-clients-common/constants/CursusPortalSettingsKeys';
const baseUrl = '/cursus/settings/portal';
const addNewKeyWordToJsonKeys = value => {
  //Adding "new-" word to all json keys
  return Object.keys(value).reduce((obj, key) => {
    const updatedKey = `new-${key}`;
    return Object.assign(obj, {
      [updatedKey]: value[key]
    });
  }, {});
};
const valueConverter = settingData => {
  if (settingData.get('settingName') === SALES_EXTENSION_DEFAULT_ASSOCIATIONS_FOR_NEW_OBJECTS) {
    /**
     * associationDefaults are represented under single object in frontend. example - {'0-1': true,'new-0-1': true,'0-2': false}
     * In backend associationDefaults are split into two keys - SALES_EXTENSION_DEFAULT_ASSOCIATIONS_FOR_EXISTING_OBJECTS
     * and SALES_EXTENSION_DEFAULT_ASSOCIATIONS_FOR_NEW_OBJECTS to support future development.
     *
     * SALES_EXTENSION_DEFAULT_ASSOCIATIONS_FOR_NEW_OBJECTS is a special case where it has values {'0-1': true} in backend.
     * addNewKeyWordToJsonKeys function modifies that value to {'new-0-1': true} to make it useful in frontend.
     */
    const jsonValue = JSON.parse(settingData.get('value'));
    return addNewKeyWordToJsonKeys(jsonValue);
  }
  const type = settingData.get('type');
  const value = settingData.get('value');
  switch (type) {
    case 'BOOLEAN':
      return value === 'true';
    case 'JSON':
      return JSON.parse(value);
    default:
      return value;
  }
};

/**
 * @typedef {import("immutable").List} List
 *
 * @typedef {Object} ImmutablePromiseClient
 * @property {(url: string) => Promise<List>} get
 */

/**
 * @param {List} portalSettings
 * @returns {Map<string, string | boolean>}
 */
const portalSettingsToMap = portalSettings => fromJS(portalSettings).reduce((portalSettingsMap, settingData) => {
  return portalSettingsMap.set(settingData.get('settingName'), valueConverter(settingData));
}, ImmutableMap());

/**
 * Get all Cursus portal settings as a Key-Value Map
 * @param {ImmutablePromiseClient} apiClient
 */
export const getAllPortalSettings = (apiClient, portalId) => apiClient.get(`${baseUrl}/all`, {
  query: {
    portalId
  }
}).then(portalSettingsToMap);