import { Record, Map as ImmutableMap } from 'immutable';
import SourceTypes from 'activity-feed-ui/constants/SourceTypes';
import ActivitySourceRecord from './ActivitySourceRecord';
import ActivityOriginDetailRecord from './ActivityOriginDetailRecord';
import ActivityTypes from '../constants/ActivityTypes';
const defaultActivityValues = {
  activityLifecycleStage: '',
  activityMeta: ImmutableMap(),
  activityOriginDetail: new ActivityOriginDetailRecord(),
  activityOriginId: '',
  activityOriginType: SourceTypes.ANONYMOUS,
  activitySource: undefined,
  activityThreadCount: ImmutableMap(),
  activityType: ActivityTypes.EMAIL_OPEN,
  createdAt: Date.now(),
  deletedAt: null,
  muted: false,
  portalId: 0,
  restoredAt: null,
  updatedAt: null,
  userId: null
};
const makeActivity = Record(defaultActivityValues, 'ActivityRecord');
export class ActivityRecord extends makeActivity {
  static create(data) {
    const activitySource = data.get('activitySource');
    const activityOriginDetail = data.get('activityOriginDetail');
    return new ActivityRecord(data).merge({
      activitySource: activitySource ? new ActivitySourceRecord(activitySource) : undefined,
      activityOriginDetail: activityOriginDetail ? new ActivityOriginDetailRecord(activityOriginDetail) : undefined
    }).update('activityOriginDetail', origin => {
      if (origin) {
        return origin.merge({
          activityOriginId: data.get('activityOriginId'),
          activityOriginType: data.get('activityOriginType')
        });
      }
      return origin;
    });
  }
  updateOriginWithContact(contact) {
    const vid = contact.get('vid');
    if (vid) {
      return this.withMutations(activity => activity.set('activityOriginId', `${vid}`).set('activityOriginType', SourceTypes.CONTACT).update('activityOriginDetail', origin => origin ? origin.mergeWithContact(contact) : origin));
    }
    return this;
  }

  // Will update all activities with same thread ID to be muted
  updateActivityMutedStatus(threadId, muted) {
    if (this.getThreadId() === threadId) {
      return this.withMutations(activity => activity.set('muted', muted));
    }
    return this;
  }
  getOrigin() {
    return this.activityOriginDetail;
  }
  getTimestamp() {
    return this.createdAt;
  }
  getThreadId() {
    // Only EMAIL_TRACKER_CREATE, EMAIL_OPEN, and EMAIL_CLICK activity types have this property,
    return this.activityMeta.getIn(['threadInfo', 'id']);
  }
  isEqual(activityRecord) {
    const {
      activityOriginType,
      activityOriginId,
      activityType,
      createdAt
    } = activityRecord;
    return this.activityOriginType === activityOriginType && this.activityOriginId === activityOriginId && this.activityType === activityType && this.createdAt === createdAt;
  }
}
export default ActivityRecord;