'use es6';

import { getCookie } from 'hub-http/helpers/cookies';
import { parseUrl } from 'hub-http/helpers/url';
export const CSRF_HEADER = 'X-Tools-CSRF';
export const INTERNAL_CSRF_COOKIE_NAME = 'internal-csrf';
const csrfCookieNameHostnameRegexPairs = [{
  cookieName: 'csrf.api',
  regEx: /^\w+(-\w+)*\.hubapi(qa)?\.com$/
}, {
  cookieName: 'csrf.app',
  regEx: /^\w+(-\w+)*\.hubspot(qa)?\.com$/
}, {
  cookieName: 'csrf.tools',
  regEx: /^\w+(-\w+)*\.hubteam(qa)?\.com$/
}, {
  cookieName: 'csrf.sidekick',
  regEx: /^\w+(-\w+)*\.getsidekick(qa)?\.com$/
}];
export const resolveLbCsrfCookieName = hostname => {
  const cookieNameHostnameRegexPair = csrfCookieNameHostnameRegexPairs.find(({
    regEx
  }) => regEx.test(hostname));
  return cookieNameHostnameRegexPair ? cookieNameHostnameRegexPair.cookieName : null;
};
export const getCsrfCookieValue = options => {
  const {
    url,
    cookies,
    forceCsrfFallback
  } = options;
  const {
    hostname
  } = parseUrl(url);
  const lbCookieName = forceCsrfFallback ? null : resolveLbCsrfCookieName(hostname);
  const lbCookie = lbCookieName && getCookie(lbCookieName, cookies);
  return lbCookie || getCookie(INTERNAL_CSRF_COOKIE_NAME, cookies);
};