import { EMAIL_TRACKING_ENABLED, SALES_EXTENSION_EMAIL_TRACKING_LEVEL, SALES_EXTENSION_EMAIL_LOGGING_LEVEL, SALES_EXTENSION_DEFAULT_ASSOCIATIONS_ENABLED, SALES_EXTENSION_DEFAULT_ASSOCIATIONS_FOR_EXISTING_OBJECTS, SALES_EXTENSION_DEFAULT_ASSOCIATIONS_FOR_NEW_OBJECTS, ExtLogAndTrackLevel } from 'sales-clients-common/constants/CursusPortalSettingsKeys';
import { Map as ImmutableMap } from 'immutable';
import { ASSOCIATIONS_DEFAULT_FALLBACK_SETTINGS } from 'sales-clients-common/constants/CustomerDataObjectTypes';
const getPortalSetting = (portalSettings, key) => portalSettings.settings.get(key);
export const isTrackingEnabledForPortalSelector = portalSettings => getPortalSetting(portalSettings, EMAIL_TRACKING_ENABLED);
export const isDefaultAssocEnabledForPortalSelector = portalSettings => {
  return getPortalSetting(portalSettings, SALES_EXTENSION_DEFAULT_ASSOCIATIONS_ENABLED);
};
export const portalAssociationDefaultsSelector = portalSettings => {
  const defaultAssocForExistingObjects = ImmutableMap(getPortalSetting(portalSettings, SALES_EXTENSION_DEFAULT_ASSOCIATIONS_FOR_EXISTING_OBJECTS));
  const defaultAssocForNewObjects = ImmutableMap(getPortalSetting(portalSettings, SALES_EXTENSION_DEFAULT_ASSOCIATIONS_FOR_NEW_OBJECTS));

  // Fallback to CONTACTS when setting is empty
  const portalDefaults = defaultAssocForExistingObjects.merge(defaultAssocForNewObjects);
  if (portalDefaults.count() > 0) {
    return portalDefaults;
  }
  return ImmutableMap(ASSOCIATIONS_DEFAULT_FALLBACK_SETTINGS);
};
export const emailTrackingLevelSelector = portalSettings => {
  return getPortalSetting(portalSettings, SALES_EXTENSION_EMAIL_TRACKING_LEVEL);
};
export const emailLoggingLevelSelector = portalSettings => {
  return getPortalSetting(portalSettings, SALES_EXTENSION_EMAIL_LOGGING_LEVEL);
};
export const trackingSetByAdministratorSelector = portalSettings => {
  if (getPortalSetting(portalSettings, SALES_EXTENSION_EMAIL_TRACKING_LEVEL) === ExtLogAndTrackLevel.USER_CONTROLLED) {
    return false;
  } else {
    return true;
  }
};
export const loggingSetByAdministratorSelector = portalSettings => {
  if (getPortalSetting(portalSettings, SALES_EXTENSION_EMAIL_LOGGING_LEVEL) === ExtLogAndTrackLevel.USER_CONTROLLED) {
    return false;
  } else {
    return true;
  }
};