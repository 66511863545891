import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
  addOnsByCompanyId: {},
  addOnsByContactId: {},
  addOnsByDomain: {}
};
export const addOnsSlice = createSlice({
  name: 'addOns',
  initialState,
  reducers: {
    loadAddOns: (_state, action) => {
      return action.payload;
    },
    updateAddOns: (state, action) => {
      return {
        addOnsByCompanyId: Object.assign({}, state.addOnsByCompanyId, action.payload.addOnsByCompanyId),
        addOnsByContactId: Object.assign({}, state.addOnsByContactId, action.payload.addOnsByContactId),
        addOnsByDomain: Object.assign({}, state.addOnsByDomain, action.payload.addOnsByDomain)
      };
    }
  }
});
export const {
  loadAddOns,
  updateAddOns
} = addOnsSlice.actions;
export default addOnsSlice.reducer;