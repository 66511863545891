import { CreatedAfterFilterValues } from 'activity-feed-ui/constants/Filters';
export const createdAfterFilterValueToTime = {
  [CreatedAfterFilterValues.ALL]: getAllTime,
  [CreatedAfterFilterValues.ONE_MONTH_AGO]: getOneMonthAgo
};
export function getAllTime() {
  return 0;
}
export function getOneMonthAgo() {
  const d = new Date();
  d.setMonth(d.getMonth() - 1);
  return d.getTime();
}