import { LORAX, CANDY_APPLE, FLINT, OZ, CALYPSO, THUNDERDOME, MARIGOLD } from 'HubStyleTokens/colors';
import { Map as ImmutableMap } from 'immutable';
const typeToNameMap = ImmutableMap({
  EMAIL_OPEN: 'sorbet',
  EMAIL_CLICK: 'candy-apple',
  EMAIL_TRACKER_CREATE: 'default',
  HUBSPOT_REVISIT: 'oz',
  COMPANYPROSPECTS_REVISIT: 'oz',
  PRESENTATION_REVISIT: 'calypso',
  MEETING_BOOKED: 'thunderdome',
  LEAD_ASSIGNED: 'marigold',
  FORM_SUBMITTED: 'marigold'
});
const typeToColorMap = ImmutableMap({
  EMAIL_OPEN: LORAX,
  EMAIL_CLICK: CANDY_APPLE,
  EMAIL_TRACKER_CREATE: FLINT,
  HUBSPOT_REVISIT: CALYPSO,
  COMPANYPROSPECTS_REVISIT: CALYPSO,
  PRESENTATION_REVISIT: OZ,
  MEETING_BOOKED: THUNDERDOME,
  LEAD_ASSIGNED: MARIGOLD,
  FORM_SUBMITTED: MARIGOLD
});
export const getColorName = activityType => typeToNameMap.get(activityType);
export const getColor = activityType => typeToColorMap.get(activityType);